.arrow-button
	.swiper-button-disabled
		opacity: 0.5
		pointer-events: none
	.swiper-button-prev,.swiper-button-next
		&::after
			content: ""
	.swiper-button-prev
		&::after
			background-image: url(../img/icon/swiper-prev.svg)
	.swiper-button-next
		&::after
			background-image: url(../img/icon/swiper-next.svg)
	.swiper-button-prev
		left: -calc(60/1920*100*1rem)
		@media (min-width: 1280px)

			left: r(-90px)
	.swiper-button-next
		right: -calc(60/1920*100*1rem)
		@media (min-width: 1280px)
			right: r(30px)
			right: r(-90px)
	.swiper-button-prev,.swiper-button-next
		margin: 0
		top: 50%
		transform: translateY(-50%)
		width: 30px
		height: 30px
		@apply rounded-full transition-all
		@media (min-width: 1280px)
			width: r(30px)
			height: r(30px)
		&::after
			height: r(20px)
			@apply bg-no-repeat w-full bg-center bg-contain
		&:hover
			@apply bg-main-799167
			&::after
				color: #fff

	@media (max-width: 1279.98px)
		display: flex
		justify-content: center
		align-items: center
		position: relative
		.swiper-button-prev,.swiper-button-next
			margin-top: calc(10/1920*100*1rem)
			position: static
			transform: translateY(0)
		.swiper-button-next
			margin-left: calc(10/1920*100*1rem)
	&.arrow-pagination
		.swiper-button-prev
			left: r(30px)
		.swiper-button-next
			right: r(30px)
		.swiper-button-prev,.swiper-button-next
			&::after
				height: r(12px)
	&.no-responsive
		@media (max-width: 1280px)
			display: contents
		.swiper-button-prev,.swiper-button-next
			position: absolute !important
			margin: 0 !important
			top: 50%
			transform: translateY(-50%)
		.swiper-button-prev
			@media (max-width: 1599.98px)
				left: -calc(40/1920*100*1rem)
			@media (max-width: 1366px)
				left: 0
		.swiper-button-next
			@media (max-width: 1599.98px)
				right: -calc(40/1920*100*1rem)
			@media (max-width: 1366px)
				right: 0
		@media (max-width: 1280px)
			.swiper-button-prev
				left: calc(10/1920*100*1rem)
			.swiper-button-next
				right: calc(10/1920*100*1rem)

.swiper-pagination
	bottom: -calc(20/1920*100*1rem) !important
	line-height: 0 !important
	span
		margin: 0 r(5px)
		border: 0
		background: transparent
		border: thin solid #fff
		opacity: 1
		transition: .3s all ease-in-out
		width: r(10px)
		height: r(10px)
		border-radius: r(5px)
		&.swiper-pagination-bullet-active
			@apply bg-white
