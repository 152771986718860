.custom-fancybox-popup
	&.fancybox__container
		.fancybox__viewport
			.fancybox__slide
				@apply p-0
				.fancybox__content
					padding-top: r(100px)
					@apply w-full px-0 pb-0
					.carousel__button
						width: r(72px)
						height: r(72px)
						right: 0
						top: 0px
						@apply bg-main-435B5A rounded-none transition-all
						i
							font-size: r(32px)
							@apply text-white font-light
						&:hover
							@apply bg-orange
					.logo-80,.medium-title,form
						@media (max-width: 1279.98px)
							padding-left: r(15px)
							padding-right: r(15px)
					.logo-80,.medium-title
						@apply text-center
					&.popup-1
						background: #DEE4DA
						.medium-title
							margin-bottom: r(30px)
							@media (min-width: 1280px)
								margin-bottom: r(75px)
						.image-bottom
							height: r(800px)
							margin-top: r(50px)
							@apply relative
							@media (min-width: 1280px)
								margin-top: r(160px)
							.cloud
								height: calc(244/800*100rem)
								@apply absolute w-full top-0 left-0
								img
									@apply h-full
							.background
								@apply relative z-3 h-full w-full
								img
									@apply h-full w-full object-cover
					form
						max-width: r(990px)
						padding: r(30px) r(30px) r(50px)
						@apply grid sm:grid-cols-2 gap-10px mx-auto md:bg-main-799167
						@media (min-width: 1280px)
							padding: r(85px) r(150px) r(130px)
						.form-group
							@apply relative h-fit
							input
								&:not([type="submit"])
									height: r(45px)
									padding: r(10px) r(15px)
									@apply w-full bg-white font-normal text-base leading-19px text-main-435B5A placeholder:text-main-435B5A
							&.form-icon
								&::after
									font-weight: 300
									font-size: r(24px)
									@apply font-Awesome6 font-light text-main-435B5A absolute top-1/2 -translate-y-1/2 right-15px pointer-events-none
								input
									@apply appearance-none
									&::-webkit-inner-spin-button,&::-webkit-calendar-picker-indicator
										opacity: 0
							&.form-date
								&::after
									content: "\e472"
							&.form-zip-code
								&::after
									content: "\f601"
						.form-ratio,.form-title,.form-address
							@apply col-span-full
						.form-title
							@apply font-normal text-lg leading-21px text-main-799167 md:text-white mt-5 mb-10px
						.form-ratio
							.wpcf7-radio,.wpcf7-checkbox
								padding-left: r(10px)
								@apply grid grid-cols-2 lg:gap-x-11 lg:gap-y-5 gap-10px
								.wpcf7-list-item
									@apply mx-0
									> label
										> span
											@apply font-normal text-sm leading-4 text-main-799167 md:text-white cursor-pointer flex items-baseline
											&::before
												content: "\f0c8"
												@apply font-light text-base left-4 font-Awesome6 transition-all mr-15px
										input
											display: none
											&:checked
												~ span
													&::before
														content: "\f14a"

						.submit-button
							@apply mt-60px
							.btn-input
								height: r(50px)
								border-radius: r(10px)
								padding: r(5px) r(49px)
								@apply rounded-full text-white flex items-center relative bg-orange w-fit
								&::before
									content: "\e20a"
									font-weight: 300
									font-size: r(32px)
									line-height: r(32px)
									@apply font-Awesome6 mr-10px
								&::after
									content: var(--data-title)
									@apply font-Philosopher font-bold text-xl leading-22px
								input[type="submit"]
									@apply opacity-0 w-full absolute top-0 left-0 h-full
						.wpcf7-response-output
							height: fit-content !important
							@apply mt-auto
					&.popup-2
						background: #D6DEE0
						.medium-title
							@apply mb-13
						.section-1
							padding-bottom: r(147px)
							@apply relative
							@media (min-width: 1280px)
								&::before
									background-image: url(../img/home/popup_cloud_1.png)
									background-size: cover
									height: r(296px)
									content: ""
									bottom: r(-80px)
									@apply w-full absolute left-0

							.wrapper
								max-width: r(1170px)
								@apply mx-auto
						.description
							max-width: r(1010px)
							@apply mx-auto font-normal text-lg leading-21px text-main-435B5A mt-25px text-center

						.order-contact-button
							border-radius: r(100px)
							margin-bottom: r(40px)
							@apply flex justify-center gap-10px mt-30px
							@media (min-width: 1280px)
								margin-bottom: r(80px)
							.btn
								width: r(160px)
								height: r(40px)
								background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #F37926
								@apply text-white font-Philosopher font-bold text-xl leading-22px flex items-center justify-center rounded-full mx-0
								&:hover
									@apply opacity-90
						.main
							.img
								a
									+img-ratio(700/1170)

						.thumb
							@apply mt-30px
							.title
								@apply font-bold text-xl leading-23px text-justify text-main-435B5A
							.ctn
								@apply font-normal text-base leading-19px text-justify text-main-435B5A mt-10px
						.swiper-btn-wrapper
							width: r(110px)
							height: r(30px)
							border-radius: r(100px)
							padding: r(5px) r(15px)
							left: 50%
							transform: translateX(-50%)
							bottom: r(40px)
							@apply absolute bg-main-435B5A/50 flex items-center justify-center z-10 select-none
							.swiper-btn
								@apply h-full
							.swiper-pagination
								font-size: 0
								@apply static flex items-center justify-center h-full
								span
									@apply border-none font-bold font-Philosopher text-lg leading-5 text-white h-auto mx-0
									&:first-child
										margin-left: r(-5px)
									& + span
										&::before
											content: "/"
							.swiper-button-prev,.swiper-button-next
								&::after
									@apply brightness-0 invert

							.swiper-button-prev
								left: 0
							.swiper-button-next
								right: 0
						.section-2
							&.section
								@media (min-width: 1280px)
									padding-top: r(75px)
									padding-bottom: r(300px)
							.booking-form
								@apply bg-main-C9D5C1
								.ezbe-form
									padding: 15px 20px
									@media (min-width: 1280px)
										padding: r(30px) r(50px)
									.ezbe-row
										@apply flex xl:flex-nowrap
										.ezbe-col-12
											flex: 0 0 33.333%
											@apply relative
											@media (min-width: 576px)
												flex: 0 0 25%
											@media (min-width: 1280px)
												flex: 0 0 calc( 1/4*50% )
											&:nth-of-type(2)
												flex: 0 0 100%
												@media (min-width: 1280px)
													flex: 0 0 50%
												@media (max-width: 1279.98px)
													@apply mb-15px
												input
													@apply absolute w-full left-0 top-0 h-full opacity-0
											&:nth-of-type(6)
												@apply hidden
											&:last-child
												@apply flex items-end
										.big-number,.custom-select .value
											font-size: r(54px)
											line-height: r(60px)
											@apply font-Philosopher text-main-435B5A font-bold
										.custom-content-wrapper
											@apply flex flex-col
											@apply font-Philosopher text-lg leading-5
											&.custom-select
												@apply flex flex-col h-full relative xl:text-center
												.title
													@apply flex-1
													@apply flex items-center xl:justify-center
													&::after
														content: "\f107"
														@apply ml-5px text-lg font-Awesome6 font-light
												select
													padding: 0 !important
													@apply text-xl h-auto p-0 absolute w-full h-full top-0 left-0 opacity-0
													&:focus
														@apply outline-0
											.top
												@apply flex items-center
												.value
													flex: 0 0 r(60px)
													@apply flex items-center
													&::after
														content: "\f107"
														@apply ml-5px text-lg font-Awesome6 font-light
												.diffDay
													border-radius: r(5px)
													padding: r(5px) r(10px)
													@apply bg-orange font-bold font-Philosopher text-white ml-5
													em
														@apply not-italic
											.bottom
												@apply flex mt-15px xl:mt-5
												.date
													@apply flex items-center
													& + .date
														@apply ml-13
													.right
														@apply font-Philosopher font-normal text-main-435B5A ml-5
														.year
															@apply mt-5px

											.order-title
												@apply font-Philosopher font-bold text-main-435B5A

									.ezbe-form-control
										border: 0 !important
										@apply bg-transparent font-Philosopher font-normal text-lg leading-5 text-main-435B5A
								input
									&.ezweb-form-submit
										border-radius: r(100px)
										height: r(40px)
										@apply bg-orange font-Philosopher font-bold text-xl leading-22px text-white w-full
										@media (min-width: 1280px)
											height: auto
											padding: r(9px) r(40px)

body
	&.homepage
		.daterangepicker
			display: block !important
			@apply mt-0 border-0 bg-transparent static w-full
			&::before,&::after
				@apply hidden
			.datepicker-wrapper
				@apply grid md:grid-cols-2 gap-0
				.ranges
					@apply absolute top-0 left-0 pointer-events-none
				.drp-calendar
					@apply float-none mx-0 max-w-full p-0
					&.left
						background: #F9F9F9
					&.right
						background: #F6F6F6
			.calendar-table
				@apply bg-transparent border-0 pt-10 pb-20
				@media (max-width: 1279.98px)
					padding: 30px 10px
			.table-condensed
				@apply mx-auto text-center
				@media (min-width: 1280px)
					max-width: r(420px)
				thead
					tr
						&:first-child
							th
								&:nth-of-type(2)
									padding: r(10px) r(20px)
									@apply bg-white font-Philosopher font-bold text-2xl leading-27px text-main-435B5A
						&:last-child
							@apply font-Philosopher font-bold text-lg leading-5 text-main-435B5A
							th
								@apply pt-15px font-Philosopher font-bold text-lg leading-5 text-main-435B5A
				tbody
					td
						width: r(56px)
						height: r(56px)
						z-index: 1
						@apply relative bg-transparent font-Philosopher font-bold text-lg leading-5 text-main-435B5A transition-all
						&.off
							&.disabled
								&::before
									@apply bg-999999/[.1]
								&.ends
									text-decoration: auto
									@apply opacity-70
									&::after
										content: ""
										left: 50%
										top: 50%
										transform: translate(-50%,-50%) rotate(-45deg)
										width: 50%
										height: 1px
										@apply absolute bg-999999
										@media (min-width: 1280px)
											height: r(1px)
								&.disabled
									@apply opacity-70
						&.in-range
							@apply text-white
							&::before
								@apply bg-orange/30
						&.active
							@apply text-white
							&::before
								@apply bg-orange
						&::before
							content: ""
							width: calc( 100% - 2.5/1920*100rem )
							height: calc( 100% - 2.5/1920*100rem )
							left: 50%
							top: 50%
							transform: translate(-50%,-50%)
							z-index: -1
							@apply absolute bg-white transition-all

.term-fancybox
	&.fancybox__container
		.fancybox__viewport
			.fancybox__slide
				@apply p-0
				.fancybox__content
					padding-top: r(72px)
					padding-bottom: r(60px)
					padding-left: 0
					padding-right: 0
					@apply w-full bg-main-C9D5C1
					@media (min-width: 1280px)
						padding-top: r(100px)
						padding-bottom: r(272px)
					.carousel__button
						width: r(72px)
						height: r(72px)
						right: 0
						top: 0px
						@apply bg-main-435B5A rounded-none transition-all
						i
							font-size: r(32px)
							@apply text-white font-light
						&:hover
							@apply bg-orange
					.logo-80,.medium-title
						@apply text-center
					.article
						margin-top: r(30px)
						padding-bottom: 20px
						padding-top: 20px
						@apply bg-white
						@media (min-width: 1280px)
							padding-top: r(70px)
							padding-bottom: r(25px)
							margin-top: r(65px)
					.article-content
						@apply mx-auto px-15px lg:px-0
						@media (min-width: 1024px)
							max-width: r(1170px)
					.wrapper
						& + .wrapper
							@apply mt-30px xl:mt-13
					.title
						border-bottom: 1px solid #C9D5C1
						@apply font-bold font-Philosopher text-xl leading-22px text-main-435B5A flex items-center pb-10px
						@media (min-width: 1280px)
							border-bottom: r(1px) solid #C9D5C1
						.id
							flex: 0 0 r(40px)
							width: r(40px)
							height: r(40px)
							@apply rounded-full bg-main-C9D5C1 flex items-center justify-center mr-5
					.content
						@apply mt-10px sm:mt-30px
						*
							text-align: justify
							@apply font-Philosopher font-normal text-lg leading-5
						table
							@apply w-full
							td
								border: thin solid
								@apply border-black py-5px px-10px
						strong
							@apply font-bold
						* + *
							@apply mt-15px
						ol
							list-style: auto
							padding-left: 17px
							@media (min-width: 1280px)
								padding-left: r(17px)
						ul
							padding-left: 17px
							@apply list-disc
							@media (min-width: 1280px)
								padding-left: r(17px)
					.read-more
						margin-top: r(30px)
						@apply flex items-center flex-col font-Philosopher font-bold text-xl leading-22px text-orange
						@media (min-width: 1280px)
							margin-top: r(72px)
						i
							font-size: r(32px)
							animation: flowdinng 1.5s infinite alternate
							@apply leading-32px mt-10px
body
	&.mac-user
		.custom-fancybox-popup
			.popup-2
				.section-2
					.custom-content-wrapper
						&.custom-select
							select
								&:focus
									visibility: visible !important
