.zoom-img
	overflow: hidden
	&.img,.img
		overflow: hidden

	&:hover
		img
			transform: scale(1.05)
	img
		transition: .3s all ease-in-out

@keyframes spin
	0%
		transform: rotate(0)
	100%
		transform: rotate(360deg)
@keyframes spin_trcen
	0%
		transform: translate(-50%,-50%) rotate(0)
	100%
		transform: translate(-50%,-50%) rotate(360deg)

@keyframes animateReversedLeftCloud
	0%
		transform: translateX(0%) scale(0.95)
	to
		transform: translateX(-30px) scale(1)

@keyframes heartbeat
	from
		transform: translate(-50%,-50%) scale(1)
		transform-origin: center center
		animation-timing-function: ease-out

	10%
		transform: translate(-50%,-50%) scale(0.91)
		animation-timing-function: ease-in

	17%
		transform: translate(-50%,-50%) scale(0.98)
		animation-timing-function: ease-out

	33%
		transform: translate(-50%,-50%) scale(0.87)
		animation-timing-function: ease-in

	45%
		transform: translate(-50%,-50%) scale(1)
		animation-timing-function: ease-out

@keyframes flowdinng
	0%
		transform: translateY(-30%) scale(1.1)
	100%
		transform: translateY(30%) scale(0.8)

@keyframes fadeIn
	0%
		opacity: 0
	100%
		opacity: 1
