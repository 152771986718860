
.initial-main
	@media (min-width: 1280px)
		#main-banner
			.img
				img
					@apply scale-100
			.container
				bottom: r(120px)
				@apply delay-500 opacity-100
			.content
				>div
					& + div
						@apply delay-750 scale-100
				.count-down
					.time
						@apply scale-100 transition-all duration-500 opacity-100
						&:nth-of-type(1)
							@apply delay-1000
						&:nth-of-type(2)
							@apply delay-1100
						&:nth-of-type(3)
							@apply delay-1200
						&:nth-of-type(4)
							@apply delay-1300
			.swiper-pagination
				@apply opacity-100 delay-1400 opacity-100
#main-banner
	.swiper
		.swiper-slide
			@apply h-auto
	.box-image
		@apply h-full
		@media (max-width: 767.98px)
			@apply flex flex-col h-full

	.img
		@apply relative md:h-full
		@media (min-width: 768px)
			&::before,&::after
				content: ""
				@apply absolute w-full h-full top-0 left-0 z-5
			&::before
				background: #F37926
				opacity: 0.25
			&::after
				background: #000000
				mix-blend-mode: multiply
				opacity: 0.25
		img
			@apply w-full h-full object-cover
			@media (min-width: 1280px)
				width: 100%
				height: 100vh
				@apply scale-125 transition-all duration-1200

	.container
		left: 50%
		bottom: 30px
		@apply static md:absolute bg-main-435B5A md:bg-transparent py-30px md:py-0 z-5 flex-1
		@media (min-width: 768px)
			transform: translateX(-50%)
		@media (min-width: 1280px)
			bottom: 0
			@apply transition-all duration-1000 opacity-0
	.content
		@media (max-width: 767.98px)
			@apply flex flex-col h-full
			.block-title
				@apply flex-1
		> div
			& + div
				@apply mb-10px xl:mt-10 transition-all duration-500 scale-75
			&:last-child
				@apply mb-0
		.button
			.btn.btn-2
				width: r(180px)
		.logo
			img
				height: r(60px)
				@apply object-contain
				@media (min-width: 1280px)
					height: r(100px)
		.count-down
			@apply flex items-center justify-center
			.time
				flex: 0 0 r(100px)
				width: r(100px)
				height: r(100px)
				@apply flex flex-col font-Philosopher font-normal relative items-center justify-center
				@media (min-width: 1280px)
					flex: 0 0 r(106px)
					width: r(106px)
					height: r(106px)
					@apply scale-75 opacity-0
				& + .time
					@apply ml-30px
				&::before
					content: ""
					left: 50%
					top: 50%
					transform: translate(-50%,-50%)
					background-image: url(../img/banner/circle.svg)
					animation: spin_trcen 5s infinite linear
					@apply bg-no-repeat bg-contain absolute w-full h-full pointer-events-none bg-center
				&:nth-of-type(2)
					&::before
						animation-delay: 0.5s
				&:nth-of-type(3)
					&::before
						animation-delay: 1s
				&:nth-of-type(4)
					&::before
						animation-delay: 1.5s

				.value
					font-size: r(35px)
					line-height: normal
					@apply font-normal
					@media (min-width: 576px)
						font-size: r(30px)
					@media (min-width: 1280px)
						font-size: r(40px)
						line-height: r(45px)
				.unit
					font-size: r(18px)
					line-height: r(20px)

	.swiper-pagination
		bottom: 10px !important
		@apply opacity-0
		@media (min-width: 1280px)
			bottom: r(10px) !important

	.block-title
		@media (min-width: 1280px)
			font-size: r(40px)
			line-height: normal

// .initial-body
// 	#main-banner
