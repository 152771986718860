.index-1
	.container
		&.section
			@media (min-width: 1280px)
				padding-top: r(155px)
				padding-bottom: r(155px)
	.image-cover
		@apply absolute w-full h-full top-0 left-0 select-none
		[class*="cloud-"]
			top: r(40px)
			@apply absolute z-3
			img
				@apply w-full h-full object-contain
		.cloud-1
			width: 40%
			@apply left-0
			@media (min-width: 1280px)
				width: r(712px)
				height: r(257px)
			img
				animation: animateReversedLeftCloud 5s cubic-bezier(0.645, 0.045, 0.355, 1) infinite alternate
		.cloud-2
			width: 70%
			@apply right-0
			@media (min-width: 1280px)
				width: r(1425px)
				height: r(475px)
			img
				animation: animateReversedLeftCloud 5s cubic-bezier(0.645, 0.045, 0.355, 1) infinite alternate
		.background
			@apply w-full h-full
			img
				@apply w-full h-full object-cover

		&::before
			content: ""
			background: linear-gradient(167.06deg, rgba(0, 0, 0, 0) 56.6%, rgba(0, 0, 0, 0.2) 63.95%, rgba(0, 0, 0, 0.2) 73.74%)
			background-blend-mode: multiply, normal
			mix-blend-mode: multiply
			@apply absolute w-full h-full top-0 left-0
		&::after
			content: ""
			background: #D6DEE0
			background-blend-mode: multiply, normal
			mix-blend-mode: multiply
			@apply absolute w-full h-full top-0 left-0
	.box-left
		@apply flex flex-col
		.logo-80
			@apply mb-5 xl:mb-8
			@media (max-width: 1279.98px)
				order: 2
			@media (max-width: 575.98px)
				@apply hidden
		.title-wrapper
			@apply xl:mb-8
			@media (max-width: 1279.98px)
				order: 3
			.block-title
				& +.block-title
					@apply xl:mt-10px
		.content
			max-width: r(625px)
			@apply font-normal text-333333 mt-30px xl:mt-8 text-lg leading-normal
			@media (max-width: 1279.98px)
				order: 4
			* + *
				@apply mt-30px lg:mt-9
		.image-wrapper
			max-width: r(800px)
			margin: 0 auto
			margin-top: r(30px)
			@apply w-full
			@media (min-width: 1280px)
				margin-top: r(77px)
				padding-right: r(30px)
				@apply mx-0
			@media (max-width: 1279.98px)
				order: 1
			.img
				flex: 0 0 calc( 50% - 15/1920*100rem )
				@media (min-width: 768px)
					flex: 0 0 r(330px)
				&:nth-of-type(1)
					a
						+img-ratio(385/329)
				&:nth-of-type(2)
					margin-left: r(30px)
					padding-top: r(85px)
					flex: 0 0 calc( 50% - 15/1920*100rem )
					@media (min-width: 768px)
						flex: 0 0 r(400px)
					@media (min-width: 1280px)
						margin-left: auto
					a
						+img-ratio(510/400)
	.box-right
		@apply mt-30px xl:mt-0
		.wrapper
			@media (min-width: 1280px)
				margin-left: r(-11px)
		.img
			a
				+img-ratio(1/2)
				@media (min-width: 1024px)
					+img-ratio(1.5/2)
				@media (min-width: 1280px)
					+img-ratio(693/598)

		.content
			margin-top: r(30px)
			@apply font-normal text-lg leading-21px text-white
			@media (min-width: 1280px)
				margin-top: r(85px)
			* + *
				@apply mt-30px lg:mt-9
