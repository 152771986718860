.bn
	.title
		@apply transition-all
	&.bn-1
		@apply relative
		.title
			left: 50%
			transform: translateX(-50%)
			padding: 0 r(15px)
			bottom: r(10px)
			@apply text-white font-Philosopher font-bold text-2xl leading-27px absolute z-5 w-full pointer-events-none line-clamp-2 sm:line-clamp-none
			@media (min-width: 576px)
				bottom: r(25px)
		.img
			@apply relative h-full
			a
				&::before
					content: ""
					background: linear-gradient(180deg, rgba(67, 91, 90, 0) 56.21%, #435B5A 100%)
					mix-blend-mode: multiply
					z-index: 2
					@apply absolute w-full h-full top-0 left-0 pointer-events-none transition-all
				&::after
					content: ""
					background: linear-gradient(180deg, rgba(243, 121, 38, 0) 48.44%, #F37926 100%)
					mix-blend-mode: normal
					z-index: 2
					@apply absolute w-full h-full top-0 left-0 pointer-events-none transition-all opacity-0
				&:hover
					&::before
						@apply opacity-0
					&::after
						@apply opacity-100 delay-100
				img
					@apply w-full h-full object-cover

	&.bn-search
		@apply relative
		.title
			@apply text-main-799167 font-Philosopher font-bold text-2xl leading-27px w-full pointer-events-none mt-15px
		.img
			@appyl relative
			a
				+img-ratio(250/400)
				&::before
					content: ""
					background: linear-gradient(180deg, rgba(67, 91, 90, 0) 56.21%, #435B5A 100%)
					mix-blend-mode: multiply
					z-index: 2
					@apply absolute w-full h-full top-0 left-0 pointer-events-none

	&.order-contact-box
		padding: r(30px) r(15px)
		@apply transition-all duration-300 flex flex-col h-full
		@media (min-width: 1280px)
			padding: r(60px) r(42.5px)
		.img
			a
				@apply transition-all duration-300
				@media (min-width: 1280px)
					+img-ratio(350/315)
		.content
			border: r(1px) solid #E4E4E4
			padding: r(20px) r(10px) r(13px)
			border: r(1px) solid
			box-shadow: 0px r(10px) r(25px) rgba(0, 0, 0, 0.0)
			@apply transition-all duration-300 border-transparent flex-1
		.title
			@apply font-Philosopher font-bold text-xl lg:text-2xl leading-30px text-main-435B5A
		.price
			@apply font-Philosopher font-bold text-lg lg:text-xl leading-30px text-orange
			&.has-line-through
				@apply line-through
		.hidden-ctn
			height: 0
			transition: height .3s ease-in-out
			@apply overflow-hidden
		.ctn
			@apply font-normal text-base leading-19px text-black mt-10px lg:mt-5 line-clamp-3
		.ct-button
			@apply mt-25px lg:mt-25px
			.subscribe-button
				.btn
					width: r(170px)
					height: r(40px)
					border-radius: r(100px)
					@apply flex items-center justify-center bg-orange font-Philosopher font-bold text-xl leading-22px text-white transition-all cursor-pointer
					&:hover
						@apply opacity-90
			.order-contact-button
				border-radius: r(100px)
				@apply flex justify-center gap-10px
				.btn
					width: r(160px)
					height: r(40px)
					@apply text-white font-Philosopher font-bold text-xl leading-22px flex items-center justify-center rounded-full mx-0 cursor-pointer
					&:first-child
						background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #F37926
					&:nth-of-type(2)
						background: #799167
					&:hover
						@apply opacity-90
		@media (min-width: 1280px)
			&.on-hover,&.on-swiper-active
				padding: 0
				// .img
				// 	a
				// 		+img-ratio(210/400)
				.content
					padding-top: r(30px)
					padding-bottom: r(45px)
					box-shadow: 0px r(10px) r(25px) rgba(0, 0, 0, 0.2)
					// border-color: #E4E4E4
				.hidden-ctn
					height: auto
					animation: fadeIn 1s
		&.stay-hover
			// padding: 0
			padding: r(15px) r(20px) 0
			.img
				a
					+img-ratio(1.3/1)
			.content
				border-color: #E4E4E4
				padding-top: r(20px)
				padding-bottom: r(45px)
			.hidden-ctn
				height: auto
