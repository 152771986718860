.index-4
	&.section
		@media (min-width: 1280px)
			padding-top: r(125px)
			padding-bottom: r(75px)
	.des,.tips
		max-width: r(1010px)
		@apply mx-auto
		@media (max-width: 1023.98px)
			@apply text-justify
	.des
		@apply text-main-435B5A font-bold text-lg leading-normal mb-25px mt-25px
	.tips
		@apply font-normal text-xl leading-normal text-orange italic
		strong
			@apply not-italic
	.index-4-slide
		margin-top: r(30px)
		@media (min-width: 1280px)
			margin-top: r(100px)
		.swiper
			@media (min-width: 1280px)
				margin: 0 r(-40.5px)
			.swiper-wrapper
				padding-bottom: r(30px)
				@media (min-width: 1280px)
					height: r(723px)
			.swiper-slide
				@apply h-auto
				.bn
					&.order-contact-box.stay-hover
						@media (max-width: 575.98px)
							padding-bottom: 0
		.swiper-pagination
			&.swiper-pagination-fraction
				font-size: 0
				bottom: 0 !important
				gap: r(2px)
				@apply font-Philosopher font-bold leading-5 text-main-435B5A flex items-center justify-center h-full
				span
					margin: 0
					border: 0
					@apply text-lg h-fit w-auto
					&.swiper-pagination-current
						&::after
							content: "/"
		.swiper-btn
			width: r(160px)
			margin: 0 auto
			height: 30px
			@media (min-width: 1280px)
				height: r(30px)
