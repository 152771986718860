.ovh
	overflow: hidden

#fixed-tool
	position: fixed
	right: calc(15/1920*100*1rem)
	transition: .3s all ease-in-out
	bottom: 5vh
	z-index: 50
	@apply pointer-events-none opacity-0
	&.active
		bottom: 10vh
		@apply pointer-events-auto opacity-100
	a
		display: flex
		justify-content: center
		align-items: center
		width: calc(40/1920*100*1rem)
		height: calc(40/1920*100*1rem)
		border-radius: 1000%
		cursor: pointer
		transition: .3s all ease-in-out
		@apply bg-main-435B5A
		& + a
			margin-top: calc(10/1920*100*1rem)
		@media (min-width: 576px)
			width: calc(50/1920*100*1rem)
			height: calc(50/1920*100*1rem)
		em
			font-weight: 300
			font-size: r(24px)
			color: #fff
			transition: .3s all ease-in-out
		&:first-child
			background: rgba(#FFFFFF ,.5 )
			em
				color: #666666

		&:hover
			@apply bg-main-799167
			em
				color: #fff

.desktop-show
	@apply hidden xl:block
.mobile-show
	@apply block xl:hidden
.tabslet-content
	@apply hidden

#overlay
	z-index: 100
	@apply fixed top-0 left-0 w-full h-full bg-transparent transition-all pointer-events-none xl:hidden
	&.active
		@apply bg-black/50 pointer-events-auto

.list-item-added
	list-style: disc
	padding-left: 15px
	@media (min-width: 1280px)
		padding-left: r(17px)
	li
		&::marker
			@apply text-222222

.icon-80
	width: r(60px)
	height: r(60px)
	@media (min-width: 1280px)
		width: r(80px)
		height: r(80px)
	i
		font-weight: 300
		font-size: r(25px)
		line-height: r(25px)
		@apply text-white
		@media (min-width: 1280px)
			font-size: r(32px)
			line-height: r(32px)

body
	&:not(.homepage)
		.form-group
			input[type="text"],input[type="tel"],input[type="email"],input[type="number"],input[type="date"],textarea,select
				background: #FFFFFF
				border: r(1px) solid #CEDEF8
				border-radius: r(5px)
				padding: r(7px) r(20px)
				padding: r(12.5px) r(20px)
				@apply text-base leading-18px placeholder:text-base w-full block

.wp-pagination
	ul
		@apply flex items-center justify-center list-none gap-10px
	li
		flex: 0 0 r(44px)
		height: r(44px)
		@apply font-bold text-lg leading-5  text-main-435B5A transition-all rounded-full border border-secondary-0C3E8A
		a
			@apply flex items-center justify-center w-full h-full
		&.active,&:hover
			@apply bg-main-435B5A border-transparent text-white

.loading-spinner
	left: 50%
	top: 50%
	transform: translate(-50%,-50%)
	@apply absolute w-full h-full flex items-center transition-all opacity-0 pointer-events-none z-20
	&::after
		content: ""
		@apply absolute w-10 h-10 rounded-full border-4 animate-spin border-gray-100 border-t-main-435B5A left-1/2
	&.active
		@apply opacity-100

.loading-mask
	@apply w-full h-full top-0 left-0 absolute transition-all pointer-events-none z-3
	&.active
		@apply pointer-events-auto cursor-not-allowed
		&::after
			@apply opacity-100
		svg
			@apply opacity-100 top-0
	&::after
		content: ""
		left: 50%
		transform: translateX(-50%)
		blur: r(10px)
		width: 110%
		@apply absolute h-full top-0 transition-all bg-white/[.9] blur-sm opacity-0 rounded-xl
	svg
		width: r(100px)
		height: r(100px)
		top: 10%
		@apply z-3 absolute left-1/2 -translate-x-1/2 -translate-y-1/2 transition-all opacity-0
.no-post-found
	@apply flex items-center text-red-500 text-center justify-center
	i
		font-size: r(20px)
		@apply mr-10px

.logo-80
	img
		height: r(80px)
		@apply object-contain

div.wpcf7
	&[role="form"]
		form
			.wpcf7-response-output
				border: 0
				padding: r(8px) r(10px)
				@apply bg-orange rounded-xl text-white text-sm m-0 mt-15px
			.form-group
				.wpcf7-not-valid-tip
					padding: r(5px) r(10px)
					@apply text-sm text-orange block mb-5px
			.submit-button
				.btn-input
					@apply w-fit
				.wpcf7-spinner
					width: r(40px)
					height: r(40px)
					left: calc( 100% + 5/1920*100rem )
					@apply absolute top-1/2 -translate-y-1/2 pointer-events-none opacity-100 bg-transparent
					&::before
						content: ""
						background-image: url(../img/icon/loading.svg)
						@apply bg-no-repeat bg-contain w-full h-full absolute opacity-0 transition-all bg-transparent animate-none

			&.submitting
				.submit-button
					.wpcf7-spinner
						&::before
							@apply opacity-100

#fixed-item
	width: r(60px)
	bottom: 0
	@apply fixed right-5px z-20 transition-all opacity-0
	a
		width: r(60px)
		height: r(60px)
		@apply flex items-center justify-center rounded-full bg-orange
		i
			font-size: r(30px)
			@apply tracking-normal text-white
	p
		@apply transition-all
		&:nth-child(2)
			a i
				border: thin solid #fff
				width: r(40px)
				height: r(40px)
				@apply rounded-full p-1.5 text-xl
		&:nth-child(3)
			a
				@apply bg-main-799167
		& + p
			@apply mt-10px
	#backToTop
		a
			background-color: #F9F9F9
		i
			@apply font-light
			@apply text-lg text-main-435B5A
	&.active
		bottom: calc( 5vh + calc(65/1920*100rem))
		@apply opacity-100

#fb-root
	[data-testid="bubble_iframe"]
		margin: 0 !important
		bottom: 5vh !important
		right: r(5px) !important
		transform: scale(0.9) translateX(5px) !important
