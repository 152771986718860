.block-title
	font-size: r(25px)
	@apply font-normal font-Philosopher
	@media (min-width: 576px)
		font-size: r(40px)
	@media (min-width: 1024px)
		font-size: r(43px)
	@media (min-width: 1280px)
		font-size: r(48px)
		line-height: r(54px)

.medium-title
	font-size: r(32px)
	line-height: 1.2
	@apply font-Philosopher font-normal
	@media (min-width: 576px)
		font-size: r(32px)
	@media (min-width: 1024px)
		font-size: r(37px)
	@media (min-width: 1280px)
		line-height: r(45px)
		font-size: r(40px)
