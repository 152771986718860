header
	z-index: 150
	@apply fixed top-0 left-0 w-full transition-all
	@media (min-width: 1280px)
		@apply -translate-y-full
	&.header-scroll
		.top
			@apply duration-500
			@media (min-width: 1280px)
				margin-top: r(-40px)
	&.initial-header
		@apply translate-y-0 duration-500 delay-700
	.top
		padding: r(10px) r(15px)
		height: 50px
		@apply bg-main-C9D5C1 transition-all relative flex items-center justify-between xl:justify-end
		@media (min-width: 576px)
			height: 60px
		@media (min-width: 1280px)
			height: r(40px)
			padding: r(6px) r(70px)
		&.search-active
			height: auto
			.hotline
				@media (max-width: 1279.98px)
					@apply opacity-0 pointer-events-none w-0
			.language
				@media (max-width: 767.98px)
					@apply opacity-0 pointer-events-none w-0
		.tools
			@apply flex items-center justify-end
		.hotline
			@apply font-Philosopher font-bold text-base leading-18px text-main-435B5A whitespace-nowrap transition-all flex items-center
			a
				@apply flex items-center
			@media (max-width: 767.98px)
				font-size: 0
			i
				@apply text-base leading-4 font-light
				@media (max-width: 767.98px)
					@apply text-base
			span
				z-index: 1
				width: 30px
				height: 30px
				@apply relative flex items-center justify-center text-white md:mr-10px
				@media (min-width: 768px)
					width: r(30px)
					height: r(30px)
				&::before
					content: ""
					left: 50%
					top: 50%
					transform: translate(-50%,-50%)
					z-index: -1
					@apply bg-main-799167 rounded-full absolute w-full h-full w-full h-full
				&::after
					content: ""
					left: 50%
					top: 50%
					transform: translate(-50%,-50%)
					width: calc( 100% + 10/1920*100rem)
					height: calc( 100% + 10/1920*100rem)
					background: rgba(#799167 ,.35 )
					z-index: -1
					animation: heartbeat 1.5s ease-in-out infinite both
					@apply rounded-full absolute

		.language
			@apply ml-10px md:ml-5 xl:ml-10 transition-all hidden
			.wpml-ls
				border: 0
			ul
				@apply flex items-center
			li
				@apply flex items-center

				& + li
					@apply ml-10px
				a
					@apply flex items-center p-0
					span
						@apply font-Philosopher font-bold text-base leading-4 text-main-435B5A
				&.wpml-ls-current-language
					span
						border-bottom: r(1px) solid
						@apply border-b-orange
		.search
			display: none !important
			@apply text-main-435B5A ml-10px md:ml-5 xl:ml-10
			i
				@apply text-base font-light leading-4
			.search-overlay
				input
					width: r(50px)
					height: r(28px)
					border-radius: r(100px)
					padding-left: r(20px)
					padding-right: r(35px)
					@apply transition-all border-none outline-none placeholder:text-transparent transition-all
				button
					left: 50%
					top: 50%
					transform: translate(-50%,-50%)
					@apply absolute transition-all pointer-events-none
				&.active
					input
						height: r(30px)
						width: r(230px)
						@media (min-width: 576px)
							height: r(40px)
							width: r(250px)
						@media (min-width: 768px)
							width: r(350px)
						@media (min-width: 1280px)
							height: r(50px)
							width: r(400px)
					button
						left: calc( 100% - 30/1920*100rem )
						transform: translate(0,-50%)
						@apply pointer-events-auto delay-200
	.logo
		left: 50%
		top: 50%
		transform: translate(-50%,-50%)
		@apply absolute z-10
		img
			height: 40px
			@apply object-contain
			@media (min-width: 576px)
				height: 50px
			@media (min-width: 1280px)
				height: r(76px)
	.bottom
		padding: 0 r(70px)
		@apply flex items-center justify-between relative bg-white shadow-lg
		nav
			@apply hidden
			@media (min-width: 1280px)
				@apply block
			> ul
				gap: r(70px)
				@apply flex items-center
				> li
					&.active,&[class*="current-"]
						> a
							@apply text-main-435B5A
							&::before
								@apply w-full
					> a
						padding: r(31px) 0
						@apply block font-Philosopher font-normal text-lg leading-5 text-333333 relative
						&::before
							content: ""
							height: r(3px)
							left: 50%
							transform: translateX(-50%)
							@apply absolute top-0 w-0 bg-orange transition-all

	.burger
		i
			@apply text-3xl transition-all duration-500
			&:nth-of-type(2)
				left: 0%
				top: 50%
				transform: translate(-50%,-50%) scale(1.1)
				@apply absolute opacity-0
		&.active
			i
				&:nth-of-type(1)
					@apply opacity-0 scale-75
				&:nth-of-type(2)
					left: 50%
					transform: translate(-50%,-50%) scale(1)
					@apply opacity-100
	.nav-mobile
		top: var(--header-height)
		height: calc( 100vh - var(--header-height))
		transition: all .75s cubic-bezier(.85,0,.15,1)
		max-width: 100%
		@apply fixed w-full left-0 bg-gradient-to-b from-main-C9D5C1 to-main-799167 overflow-auto -left-full
		@media (min-width: 576px)
			max-width: 320px
		&.active
			@apply left-0
			.nav-mobile-wrapper
				&::before
					bottom: 10px
					@apply opacity-50 delay-500
				nav
					>ul
						>li
							@apply opacity-100 translate-x-0
		.nav-mobile-wrapper
			padding: r(20px) r(30px)
			@apply h-full overflow-hidden
			&::before
				content: ""
				background-image: url(../img/logo_no_text.svg)
				background-size: contain
				left: 50%
				transform: translateX(-50%)
				bottom: -10%
				width: r(280px)
				height: r(280px)
				z-index: 01
				@apply absolute opacity-0 pointer-events-none bg-no-repeat bg-center transition-all duration-300
				@media (min-width: 576px)
					width: r(280px)
					height: r(280px)

		nav
			> ul
				@apply flex flex-col
				> li
					@apply w-fit opacity-0 -translate-x-5 transition-all duration-500
					&.active,&:hover,&[class*="current-"]
						> a
							@apply text-main-435B5A
							&::before
								@apply w-full
					> a
						padding: r(10px) 0
						@apply block font-Philosopher font-normal text-2xl sm:text-lg leading-5 text-333333 relative w-fit
						@media (min-width: 576px)
							padding: r(5px) 0
						&::before
							content: ""
							height: r(1px)
							left: 50%
							transform: translateX(-50%)
							@apply absolute bottom-0 w-0 bg-orange transition-all

main
	margin-top: var(--header-height,0)
.homepage
	main
		@media (min-width: 1280px)
			margin-top: 0
