.index-6.section
	background: linear-gradient(180deg, rgba(234, 250, 254, 0) 0%, #EAFAFE 45.31%)
	padding-bottom: r(340px)
	@media (min-width: 1280px)
		padding-top: 0
		margin-top: r(-64px)
		padding-bottom: r(325px)
	&::before
		content: ""
		width: r(622px)
		height: r(415px)
		opacity: 0.75
		background-image: url("../img/home/index-6/prop.png")
		@apply absolute bottom-0 left-0 bg-no-repeat bg-contain
	.search-faq
		max-width: r(450px)
		margin-top: 15px
		margin-bottom: 15px
		@apply mx-auto relative
		@media (min-width: 576px)
			margin-top: r(60px)
			margin-bottom: r(60px)
		input
			width: 100%
			height: r(45px)
			padding: r(5px) r(50px) r(5px) r(15px)
			@apply font-normal text-base text-main-435B5A placeholder:text-main-435B5A bg-white
		button
			width: r(45px)
			top: 0
			@apply absolute right-0 h-full flex items-center justify-center
			i
				@apply text-main-435B5A text-2xl leading-24px font-light
	.search-result
		max-width: r(1170px)
		@apply mx-auto -mt-10px
		.list
			@apply relative
		.item
			border-bottom: r(1px) solid rgba(150, 166, 140, 0.3)
			@apply transition-all
			.top
				@apply flex items-center py-10px transition-all cursor-s-resize
			&:hover
				@apply bg-main-799167/30
			.id
				flex: 0 0 r(44px)
				height: r(44px)
				@apply bg-main-799167 rounded-full flex items-center justify-center text-white font-Philosopher text-lg leading-5
			.title
				@apply ml-5 lg:ml-60px font-bold text-base sm:text-lg leading-21px text-main-435B5A text-left
			.link
				@apply ml-auto text-orange font-Philosopher text-lg leading-5 flex items-center justify-center whitespace-nowrap text-left
				@media (max-width: 575.98px)
					font-size: 0
				i
					@apply text-2xl font-light ml-10px
			.bottom
				padding: r(10px) 0 r(20px)
				@apply text-left hidden text-base text-main-799167 leading-6
			&.active
				.top
					@apply cursor-n-resize
