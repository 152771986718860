.global-breadcrumb
	@apply bg-background-F5F5F5
	.rank-math-breadcrumb
		>
			p
				@apply flex items-center flex-wrap
				> *
					@apply flex
		span
			&.separator
				font-size: 0
				@apply mx-10px flex items-center
				&::before
					content: "\f105"
					@apply text-222222 font-light text-base leading-4 font-Awesome6
		a,span
			padding: r(10.5px) 0
			max-height: 40px
			@apply font-normal text-sm leading-4 text-222222 flex items-center
			&:first-child
				font-size: 0
				&::before
					content: "\f015"
					@apply font-Awesome6 font-light text-base leading-4 text-222222
		& + li
			@apply ml-15px pl-15px relative
			&::before
				content: "\f105"
				@apply font-Awesome6 absolute top-1/2 -translate-y-1/2 left-0 text-222222 text-base
