.index-3
	.pattern-cover
		z-index: 0
		@apply absolute w-full h-full top-0 left-0 bg-333333
		&::before
			content: ""
			z-index: 2
			background: #435B5A
			mix-blend-mode: color
			opacity: 0.75
			@apply absolute w-full h-full top-0 left-0
		&::after
			content: ""
			z-index: 2
			background: #fff
			opacity: .30
			mix-blend-mode: soft-light
			@apply absolute w-full h-full top-0 left-0
	.container
		&.section
			@media (min-width: 1280px)
				padding-top: r(130px)
				padding-bottom: r(160px)
	.logo
		img
			height: r(80px)
			@apply object-contain
	.news-list-grid
		margin-top: r(30px)
		@apply grid grid-cols-2 md:grid-cols-3 xl:gap-30px lg:gap-10px gap-5px
		@media (min-width: 1280px)
			grid-template-rows: r(310px) r(135px) r(310px)
			margin-top: r(75px)
		@media (max-width: 767.98px)
			@apply hidden
		[class*="bn-"]
			@media (min-width: 768px)
				.img
					@apply h-full
					a
						height: 100% !important
				&:nth-of-type(1)
					grid-area: 1 / 1 / 2 / 2
					.img
						a
							+img-ratio(310/450)
				&:nth-of-type(2)
					grid-area: 1 / 2 / 3 / 4
					.img
						a
							+img-ratio(475/930)
				&:nth-of-type(3)
					grid-area: 2 / 1 / 4 / 2
					.img
						a
							+img-ratio(475/450)
				&:nth-of-type(4)
					grid-area: 3 / 2 / 4 / 3
					.img
						a
							+img-ratio(310/450)
				&:nth-of-type(5)
					grid-area: 3 / 3 / 4 / 4
					.img
						a
							+img-ratio(310/450)
		.bn
			@media (min-width: 1280px)
				transform: translateY(calc( 100% - var(--data-percent,0)))
				opacity: var(--data-percent,0)
			.img
				// &::before
				// 	content: ""
				// 	height: calc( 100% - var(--data-percent,0))
				// 	opacity: calc( 100% - var(--data-percent,0))
				// 	@apply w-full absolute z-5 left-0 bottom-0 from-main-799167 to-main-435B5A bg-gradient-to-tr
				a
					@media (max-width: 767.98px)
						+img-ratio(310/450)
			&.done-animation
				transform: translateY(0)
				@apply opacity-100
				.img
					&::before
						@apply opacity-0
	.index-3-slide
		@media (min-width: 768px)
			@apply hidden
		.swiper-pagination
			bottom: -15px !important
		.bn
			.img
				a
					+img-ratio(310/450)
