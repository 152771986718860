@charset "UTF-8";
body, html {
  font-size: 17px;
}

@media (min-width: 576px) {
  body, html {
    font-size: 16px;
  }
}
@media (min-width: 1024px) {
  body, html {
    font-size: 16px;
  }
}
@media (min-width: 1280px) {
  body, html {
    font-size: 1vw;
  }
}
main {
  max-width: 100vw;
  overflow-x: hidden;
}

::selection {
  color: #fff;
  @apply bg-main-799167;
}

[tabindex]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0;
  margin-left: 0;
}

blockquote {
  margin: 0;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  text-decoration: none;
  background-color: transparent;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

a:not([href]):not([tabindex]) {
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto;
}

figure {
  margin: 0;
}

img {
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0;
  padding-bottom: 0;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0;
}

button {
  border-radius: 0;
}

button:focus {
  outline: none;
  outline: calc(5/1920*100*1rem) auto -webkit-focus-ring-color;
}

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button, [type=button], [type=reset], [type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner, [type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio], input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date], input[type=time], input[type=datetime-local], input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

input:focus, button:focus, a:focus, .button-prev:focus, .button-next:focus, textarea:focus, select:focus {
  outline: none !important;
}

img.noImage {
  padding: 5% !important;
  object-fit: contain !important;
}

.zoom-img {
  overflow: hidden;
}

.zoom-img.img, .zoom-img .img {
  overflow: hidden;
}

.zoom-img:hover img {
  transform: scale(1.05);
}

.zoom-img img {
  transition: 0.3s all ease-in-out;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin_trcen {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes animateReversedLeftCloud {
  0% {
    transform: translateX(0%) scale(0.95);
  }
  to {
    transform: translateX(-30px) scale(1);
  }
}
@keyframes heartbeat {
  from {
    transform: translate(-50%, -50%) scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: translate(-50%, -50%) scale(0.91);
    animation-timing-function: ease-in;
  }
  17% {
    transform: translate(-50%, -50%) scale(0.98);
    animation-timing-function: ease-out;
  }
  33% {
    transform: translate(-50%, -50%) scale(0.87);
    animation-timing-function: ease-in;
  }
  45% {
    transform: translate(-50%, -50%) scale(1);
    animation-timing-function: ease-out;
  }
}
@keyframes flowdinng {
  0% {
    transform: translateY(-30%) scale(1.1);
  }
  100% {
    transform: translateY(30%) scale(0.8);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.button {
  transition: 0.3s all ease-in-out;
  user-select: none;
}

@media (min-width: 576px) {
  .button * {
    white-space: nowrap;
  }
}
.button .btn {
  user-select: none;
  cursor: pointer;
  width: fit-content;
  transition: 0.3s all ease-in-out;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3645833333rem 1.015625rem;
  border-radius: 4.6875rem;
  border: 0.0520833333rem solid transparent;
  height: 35px;
  @apply text-white text-base transition-all;
}

@media (min-width: 1280px) {
  .button .btn {
    height: 2.6041666667rem;
  }
}
.button .btn.btn-header {
  height: 1.5625rem;
  width: 7.96875rem;
  @apply text-main-435B5A border-main-435B5A text-sm;
}

.button .btn.btn-header i {
  @apply mr-10px text-base;
}

.button .btn.btn-header:hover {
  @apply bg-main-799167 border-transparent text-white;
}

.button .btn.btn-1 {
  @apply bg-main-435B5A text-white;
}

.button .btn.btn-1:hover {
  @apply bg-main-799167;
}

.button .btn.btn-2 {
  @apply bg-orange text-xl text-white;
}

.button .btn.btn-2 i {
  font-weight: 300;
  font-size: 1.25rem;
  @apply mr-10px;
}

.button .btn.btn-2:hover {
  @apply bg-main-799167;
}

.button .btn.btn-3 {
  @apply text-orange text-xl leading-22px font-bold font-Philosopher;
}

.button .btn.btn-3 i {
  font-size: 1.6666666667rem;
  line-height: 1.6666666667rem;
  @apply font-light mr-10px;
}

.button .btn.has-input-submit {
  @apply relative;
}

.button .btn.has-input-submit span.wpcf7-spinner {
  left: calc( 100% + 30/1920*100rem );
  @apply absolute left-full top-1/2 -translate-y-1/2;
}

.button .btn.has-input-submit::before {
  content: attr(data-title);
  @apply normal-case;
}

.button .btn.has-input-submit input {
  font-size: 0;
  @apply absolute top-0 left-0 w-full h-full;
}

.button-wrapper {
  @apply flex gap-10px;
}

.section {
  padding: 35px 0;
}

@media (min-width: 768px) {
  .section {
    padding: 40px 0;
  }
}
@media (min-width: 1024px) {
  .section {
    padding: 45px 0;
  }
}
@media (min-width: 1280px) {
  .section {
    padding: 3.125rem 0;
  }
}
.arrow-button .swiper-button-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.arrow-button .swiper-button-prev::after, .arrow-button .swiper-button-next::after {
  content: "";
}

.arrow-button .swiper-button-prev::after {
  background-image: url(../img/icon/swiper-prev.svg);
}

.arrow-button .swiper-button-next::after {
  background-image: url(../img/icon/swiper-next.svg);
}

.arrow-button .swiper-button-prev {
  left: -calc(3.125rem);
}

@media (min-width: 1280px) {
  .arrow-button .swiper-button-prev {
    left: -4.6875rem;
  }
}
.arrow-button .swiper-button-next {
  right: -calc(3.125rem);
}

@media (min-width: 1280px) {
  .arrow-button .swiper-button-next {
    right: 1.5625rem;
    right: -4.6875rem;
  }
}
.arrow-button .swiper-button-prev, .arrow-button .swiper-button-next {
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  @apply rounded-full transition-all;
}

@media (min-width: 1280px) {
  .arrow-button .swiper-button-prev, .arrow-button .swiper-button-next {
    width: 1.5625rem;
    height: 1.5625rem;
  }
}
.arrow-button .swiper-button-prev::after, .arrow-button .swiper-button-next::after {
  height: 1.0416666667rem;
  @apply bg-no-repeat w-full bg-center bg-contain;
}

.arrow-button .swiper-button-prev:hover, .arrow-button .swiper-button-next:hover {
  @apply bg-main-799167;
}

.arrow-button .swiper-button-prev:hover::after, .arrow-button .swiper-button-next:hover::after {
  color: #fff;
}

@media (max-width: 1279.98px) {
  .arrow-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .arrow-button .swiper-button-prev, .arrow-button .swiper-button-next {
    margin-top: calc(10/1920*100*1rem);
    position: static;
    transform: translateY(0);
  }

  .arrow-button .swiper-button-next {
    margin-left: calc(10/1920*100*1rem);
  }
}
.arrow-button.arrow-pagination .swiper-button-prev {
  left: 1.5625rem;
}

.arrow-button.arrow-pagination .swiper-button-next {
  right: 1.5625rem;
}

.arrow-button.arrow-pagination .swiper-button-prev::after, .arrow-button.arrow-pagination .swiper-button-next::after {
  height: 0.625rem;
}

@media (max-width: 1280px) {
  .arrow-button.no-responsive {
    display: contents;
  }
}
.arrow-button.no-responsive .swiper-button-prev, .arrow-button.no-responsive .swiper-button-next {
  position: absolute !important;
  margin: 0 !important;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 1599.98px) {
  .arrow-button.no-responsive .swiper-button-prev {
    left: -calc(2.0833333333rem);
  }
}
@media (max-width: 1366px) {
  .arrow-button.no-responsive .swiper-button-prev {
    left: 0;
  }
}
@media (max-width: 1599.98px) {
  .arrow-button.no-responsive .swiper-button-next {
    right: -calc(2.0833333333rem);
  }
}
@media (max-width: 1366px) {
  .arrow-button.no-responsive .swiper-button-next {
    right: 0;
  }
}
@media (max-width: 1280px) {
  .arrow-button.no-responsive .swiper-button-prev {
    left: calc(10/1920*100*1rem);
  }

  .arrow-button.no-responsive .swiper-button-next {
    right: calc(10/1920*100*1rem);
  }
}
.swiper-pagination {
  bottom: -calc(1.0416666667rem) !important;
  line-height: 0 !important;
}

.swiper-pagination span {
  margin: 0 0.2604166667rem;
  border: 0;
  background: transparent;
  border: thin solid #fff;
  opacity: 1;
  transition: 0.3s all ease-in-out;
  width: 0.5208333333rem;
  height: 0.5208333333rem;
  border-radius: 0.2604166667rem;
}

.swiper-pagination span.swiper-pagination-bullet-active {
  @apply bg-white;
}

.block-title {
  font-size: 1.3020833333rem;
  @apply font-normal font-Philosopher;
}

@media (min-width: 576px) {
  .block-title {
    font-size: 2.0833333333rem;
  }
}
@media (min-width: 1024px) {
  .block-title {
    font-size: 2.2395833333rem;
  }
}
@media (min-width: 1280px) {
  .block-title {
    font-size: 2.5rem;
    line-height: 2.8125rem;
  }
}
.medium-title {
  font-size: 1.6666666667rem;
  line-height: 1.2;
  @apply font-Philosopher font-normal;
}

@media (min-width: 576px) {
  .medium-title {
    font-size: 1.6666666667rem;
  }
}
@media (min-width: 1024px) {
  .medium-title {
    font-size: 1.9270833333rem;
  }
}
@media (min-width: 1280px) {
  .medium-title {
    line-height: 2.34375rem;
    font-size: 2.0833333333rem;
  }
}
.ovh {
  overflow: hidden;
}

#fixed-tool {
  position: fixed;
  right: calc(15/1920*100*1rem);
  transition: 0.3s all ease-in-out;
  bottom: 5vh;
  z-index: 50;
  @apply pointer-events-none opacity-0;
}

#fixed-tool.active {
  bottom: 10vh;
  @apply pointer-events-auto opacity-100;
}

#fixed-tool a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(40/1920*100*1rem);
  height: calc(40/1920*100*1rem);
  border-radius: 1000%;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  @apply bg-main-435B5A;
}

#fixed-tool a + a {
  margin-top: calc(10/1920*100*1rem);
}

@media (min-width: 576px) {
  #fixed-tool a {
    width: calc(50/1920*100*1rem);
    height: calc(50/1920*100*1rem);
  }
}
#fixed-tool a em {
  font-weight: 300;
  font-size: 1.25rem;
  color: #fff;
  transition: 0.3s all ease-in-out;
}

#fixed-tool a:first-child {
  background: rgba(255, 255, 255, 0.5);
}

#fixed-tool a:first-child em {
  color: #666666;
}

#fixed-tool a:hover {
  @apply bg-main-799167;
}

#fixed-tool a:hover em {
  color: #fff;
}

.desktop-show {
  @apply hidden xl:block;
}

.mobile-show {
  @apply block xl:hidden;
}

.tabslet-content {
  @apply hidden;
}

#overlay {
  z-index: 100;
  @apply fixed top-0 left-0 w-full h-full bg-transparent transition-all pointer-events-none xl:hidden;
}

#overlay.active {
  @apply bg-black/50 pointer-events-auto;
}

.list-item-added {
  list-style: disc;
  padding-left: 15px;
}

@media (min-width: 1280px) {
  .list-item-added {
    padding-left: 0.8854166667rem;
  }
}
.list-item-added li::marker {
  @apply text-222222;
}

.icon-80 {
  width: 3.125rem;
  height: 3.125rem;
}

@media (min-width: 1280px) {
  .icon-80 {
    width: 4.1666666667rem;
    height: 4.1666666667rem;
  }
}
.icon-80 i {
  font-weight: 300;
  font-size: 1.3020833333rem;
  line-height: 1.3020833333rem;
  @apply text-white;
}

@media (min-width: 1280px) {
  .icon-80 i {
    font-size: 1.6666666667rem;
    line-height: 1.6666666667rem;
  }
}
body:not(.homepage) .form-group input[type=text], body:not(.homepage) .form-group input[type=tel], body:not(.homepage) .form-group input[type=email], body:not(.homepage) .form-group input[type=number], body:not(.homepage) .form-group input[type=date], body:not(.homepage) .form-group textarea, body:not(.homepage) .form-group select {
  background: #FFFFFF;
  border: 0.0520833333rem solid #CEDEF8;
  border-radius: 0.2604166667rem;
  padding: 0.3645833333rem 1.0416666667rem;
  padding: 0.6510416667rem 1.0416666667rem;
  @apply text-base leading-18px placeholder:text-base w-full block;
}

.wp-pagination ul {
  @apply flex items-center justify-center list-none gap-10px;
}

.wp-pagination li {
  flex: 0 0 2.2916666667rem;
  height: 2.2916666667rem;
  @apply font-bold text-lg leading-5  text-main-435B5A transition-all rounded-full border border-secondary-0C3E8A;
}

.wp-pagination li a {
  @apply flex items-center justify-center w-full h-full;
}

.wp-pagination li.active, .wp-pagination li:hover {
  @apply bg-main-435B5A border-transparent text-white;
}

.loading-spinner {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @apply absolute w-full h-full flex items-center transition-all opacity-0 pointer-events-none z-20;
}

.loading-spinner::after {
  content: "";
  @apply absolute w-10 h-10 rounded-full border-4 animate-spin border-gray-100 border-t-main-435B5A left-1/2;
}

.loading-spinner.active {
  @apply opacity-100;
}

.loading-mask {
  @apply w-full h-full top-0 left-0 absolute transition-all pointer-events-none z-3;
}

.loading-mask.active {
  @apply pointer-events-auto cursor-not-allowed;
}

.loading-mask.active::after {
  @apply opacity-100;
}

.loading-mask.active svg {
  @apply opacity-100 top-0;
}

.loading-mask::after {
  content: "";
  left: 50%;
  transform: translateX(-50%);
  blur: 0.5208333333rem;
  width: 110%;
  @apply absolute h-full top-0 transition-all bg-white/[.9] blur-sm opacity-0 rounded-xl;
}

.loading-mask svg {
  width: 5.2083333333rem;
  height: 5.2083333333rem;
  top: 10%;
  @apply z-3 absolute left-1/2 -translate-x-1/2 -translate-y-1/2 transition-all opacity-0;
}

.no-post-found {
  @apply flex items-center text-red-500 text-center justify-center;
}

.no-post-found i {
  font-size: 1.0416666667rem;
  @apply mr-10px;
}

.logo-80 img {
  height: 4.1666666667rem;
  @apply object-contain;
}

div.wpcf7[role=form] form .wpcf7-response-output {
  border: 0;
  padding: 0.4166666667rem 0.5208333333rem;
  @apply bg-orange rounded-xl text-white text-sm m-0 mt-15px;
}

div.wpcf7[role=form] form .form-group .wpcf7-not-valid-tip {
  padding: 0.2604166667rem 0.5208333333rem;
  @apply text-sm text-orange block mb-5px;
}

div.wpcf7[role=form] form .submit-button .btn-input {
  @apply w-fit;
}

div.wpcf7[role=form] form .submit-button .wpcf7-spinner {
  width: 2.0833333333rem;
  height: 2.0833333333rem;
  left: calc( 100% + 5/1920*100rem );
  @apply absolute top-1/2 -translate-y-1/2 pointer-events-none opacity-100 bg-transparent;
}

div.wpcf7[role=form] form .submit-button .wpcf7-spinner::before {
  content: "";
  background-image: url(../img/icon/loading.svg);
  @apply bg-no-repeat bg-contain w-full h-full absolute opacity-0 transition-all bg-transparent animate-none;
}

div.wpcf7[role=form] form.submitting .submit-button .wpcf7-spinner::before {
  @apply opacity-100;
}

#fixed-item {
  width: 3.125rem;
  bottom: 0;
  @apply fixed right-5px z-20 transition-all opacity-0;
}

#fixed-item a {
  width: 3.125rem;
  height: 3.125rem;
  @apply flex items-center justify-center rounded-full bg-orange;
}

#fixed-item a i {
  font-size: 1.5625rem;
  @apply tracking-normal text-white;
}

#fixed-item p {
  @apply transition-all;
}

#fixed-item p:nth-child(2) a i {
  border: thin solid #fff;
  width: 2.0833333333rem;
  height: 2.0833333333rem;
  @apply rounded-full p-1.5 text-xl;
}

#fixed-item p:nth-child(3) a {
  @apply bg-main-799167;
}

#fixed-item p + p {
  @apply mt-10px;
}

#fixed-item #backToTop a {
  background-color: #F9F9F9;
}

#fixed-item #backToTop i {
  @apply font-light;
  @apply text-lg text-main-435B5A;
}

#fixed-item.active {
  bottom: calc( 5vh + calc(65/1920*100rem));
  @apply opacity-100;
}

#fb-root [data-testid=bubble_iframe] {
  margin: 0 !important;
  bottom: 5vh !important;
  right: 0.2604166667rem !important;
  transform: scale(0.9) translateX(5px) !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  *,
*::before,
*::after {
    box-sizing: border-box;
  }

  *::-webkit-scrollbar-track {
    background-color: #999;
  }

  *::-webkit-scrollbar {
    width: 0;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 0;
    border: 0.1041666667rem solid #999;
    @apply bg-main-435B5A;
  }

  html {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    line-height: 1.5;
    font-weight: 400;
    @apply font-body text-333333;
  }

  img {
    display: inline;
  }
}
.bn .title {
  @apply transition-all;
}

.bn.bn-1 {
  @apply relative;
}

.bn.bn-1 .title {
  left: 50%;
  transform: translateX(-50%);
  padding: 0 0.78125rem;
  bottom: 0.5208333333rem;
  @apply text-white font-Philosopher font-bold text-2xl leading-27px absolute z-5 w-full pointer-events-none line-clamp-2 sm:line-clamp-none;
}

@media (min-width: 576px) {
  .bn.bn-1 .title {
    bottom: 1.3020833333rem;
  }
}
.bn.bn-1 .img {
  @apply relative h-full;
}

.bn.bn-1 .img a::before {
  content: "";
  background: linear-gradient(180deg, rgba(67, 91, 90, 0) 56.21%, #435B5A 100%);
  mix-blend-mode: multiply;
  z-index: 2;
  @apply absolute w-full h-full top-0 left-0 pointer-events-none transition-all;
}

.bn.bn-1 .img a::after {
  content: "";
  background: linear-gradient(180deg, rgba(243, 121, 38, 0) 48.44%, #F37926 100%);
  mix-blend-mode: normal;
  z-index: 2;
  @apply absolute w-full h-full top-0 left-0 pointer-events-none transition-all opacity-0;
}

.bn.bn-1 .img a:hover::before {
  @apply opacity-0;
}

.bn.bn-1 .img a:hover::after {
  @apply opacity-100 delay-100;
}

.bn.bn-1 .img a img {
  @apply w-full h-full object-cover;
}

.bn.bn-search {
  @apply relative;
}

.bn.bn-search .title {
  @apply text-main-799167 font-Philosopher font-bold text-2xl leading-27px w-full pointer-events-none mt-15px;
}

.bn.bn-search .img {
  @appyl relative;
}

.bn.bn-search .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 62.5%;
}

.bn.bn-search .img a img, .bn.bn-search .img a iframe, .bn.bn-search .img a video, .bn.bn-search .img a canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.bn.bn-search .img a::before {
  content: "";
  background: linear-gradient(180deg, rgba(67, 91, 90, 0) 56.21%, #435B5A 100%);
  mix-blend-mode: multiply;
  z-index: 2;
  @apply absolute w-full h-full top-0 left-0 pointer-events-none;
}

.bn.order-contact-box {
  padding: 1.5625rem 0.78125rem;
  @apply transition-all duration-300 flex flex-col h-full;
}

@media (min-width: 1280px) {
  .bn.order-contact-box {
    padding: 3.125rem 2.2135416667rem;
  }
}
.bn.order-contact-box .img a {
  @apply transition-all duration-300;
}

@media (min-width: 1280px) {
  .bn.order-contact-box .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 111.1111111111%;
  }

  .bn.order-contact-box .img a img, .bn.order-contact-box .img a iframe, .bn.order-contact-box .img a video, .bn.order-contact-box .img a canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
.bn.order-contact-box .content {
  border: 0.0520833333rem solid #E4E4E4;
  padding: 1.0416666667rem 0.5208333333rem 0.6770833333rem;
  border: 0.0520833333rem solid;
  box-shadow: 0px 0.5208333333rem 1.3020833333rem rgba(0, 0, 0, 0);
  @apply transition-all duration-300 border-transparent flex-1;
}

.bn.order-contact-box .title {
  @apply font-Philosopher font-bold text-xl lg:text-2xl leading-30px text-main-435B5A;
}

.bn.order-contact-box .price {
  @apply font-Philosopher font-bold text-lg lg:text-xl leading-30px text-orange;
}

.bn.order-contact-box .price.has-line-through {
  @apply line-through;
}

.bn.order-contact-box .hidden-ctn {
  height: 0;
  transition: height 0.3s ease-in-out;
  @apply overflow-hidden;
}

.bn.order-contact-box .ctn {
  @apply font-normal text-base leading-19px text-black mt-10px lg:mt-5 line-clamp-3;
}

.bn.order-contact-box .ct-button {
  @apply mt-25px lg:mt-25px;
}

.bn.order-contact-box .ct-button .subscribe-button .btn {
  width: 8.8541666667rem;
  height: 2.0833333333rem;
  border-radius: 5.2083333333rem;
  @apply flex items-center justify-center bg-orange font-Philosopher font-bold text-xl leading-22px text-white transition-all cursor-pointer;
}

.bn.order-contact-box .ct-button .subscribe-button .btn:hover {
  @apply opacity-90;
}

.bn.order-contact-box .ct-button .order-contact-button {
  border-radius: 5.2083333333rem;
  @apply flex justify-center gap-10px;
}

.bn.order-contact-box .ct-button .order-contact-button .btn {
  width: 8.3333333333rem;
  height: 2.0833333333rem;
  @apply text-white font-Philosopher font-bold text-xl leading-22px flex items-center justify-center rounded-full mx-0 cursor-pointer;
}

.bn.order-contact-box .ct-button .order-contact-button .btn:first-child {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #F37926;
}

.bn.order-contact-box .ct-button .order-contact-button .btn:nth-of-type(2) {
  background: #799167;
}

.bn.order-contact-box .ct-button .order-contact-button .btn:hover {
  @apply opacity-90;
}

@media (min-width: 1280px) {
  .bn.order-contact-box.on-hover, .bn.order-contact-box.on-swiper-active {
    padding: 0;
  }

  .bn.order-contact-box.on-hover .content, .bn.order-contact-box.on-swiper-active .content {
    padding-top: 1.5625rem;
    padding-bottom: 2.34375rem;
    box-shadow: 0px 0.5208333333rem 1.3020833333rem rgba(0, 0, 0, 0.2);
  }

  .bn.order-contact-box.on-hover .hidden-ctn, .bn.order-contact-box.on-swiper-active .hidden-ctn {
    height: auto;
    animation: fadeIn 1s;
  }
}
.bn.order-contact-box.stay-hover {
  padding: 0.78125rem 1.0416666667rem 0;
}

.bn.order-contact-box.stay-hover .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 130%;
}

.bn.order-contact-box.stay-hover .img a img, .bn.order-contact-box.stay-hover .img a iframe, .bn.order-contact-box.stay-hover .img a video, .bn.order-contact-box.stay-hover .img a canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.bn.order-contact-box.stay-hover .content {
  border-color: #E4E4E4;
  padding-top: 1.0416666667rem;
  padding-bottom: 2.34375rem;
}

.bn.order-contact-box.stay-hover .hidden-ctn {
  height: auto;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide {
  @apply p-0;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content {
  padding-top: 5.2083333333rem;
  @apply w-full px-0 pb-0;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .carousel__button {
  width: 3.75rem;
  height: 3.75rem;
  right: 0;
  top: 0px;
  @apply bg-main-435B5A rounded-none transition-all;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .carousel__button i {
  font-size: 1.6666666667rem;
  @apply text-white font-light;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .carousel__button:hover {
  @apply bg-orange;
}

@media (max-width: 1279.98px) {
  .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .logo-80, .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .medium-title, .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form {
    padding-left: 0.78125rem;
    padding-right: 0.78125rem;
  }
}
.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .logo-80, .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .medium-title {
  @apply text-center;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-1 {
  background: #DEE4DA;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-1 .medium-title {
  margin-bottom: 1.5625rem;
}

@media (min-width: 1280px) {
  .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-1 .medium-title {
    margin-bottom: 3.90625rem;
  }
}
.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-1 .image-bottom {
  height: 41.6666666667rem;
  margin-top: 2.6041666667rem;
  @apply relative;
}

@media (min-width: 1280px) {
  .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-1 .image-bottom {
    margin-top: 8.3333333333rem;
  }
}
.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-1 .image-bottom .cloud {
  height: calc(244/800*100rem);
  @apply absolute w-full top-0 left-0;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-1 .image-bottom .cloud img {
  @apply h-full;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-1 .image-bottom .background {
  @apply relative z-3 h-full w-full;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-1 .image-bottom .background img {
  @apply h-full w-full object-cover;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form {
  max-width: 51.5625rem;
  padding: 1.5625rem 1.5625rem 2.6041666667rem;
  @apply grid sm:grid-cols-2 gap-10px mx-auto md:bg-main-799167;
}

@media (min-width: 1280px) {
  .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form {
    padding: 4.4270833333rem 7.8125rem 6.7708333333rem;
  }
}
.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-group {
  @apply relative h-fit;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-group input:not([type=submit]) {
  height: 2.34375rem;
  padding: 0.5208333333rem 0.78125rem;
  @apply w-full bg-white font-normal text-base leading-19px text-main-435B5A placeholder:text-main-435B5A;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-group.form-icon::after {
  font-weight: 300;
  font-size: 1.25rem;
  @apply font-Awesome6 font-light text-main-435B5A absolute top-1/2 -translate-y-1/2 right-15px pointer-events-none;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-group.form-icon input {
  @apply appearance-none;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-group.form-icon input::-webkit-inner-spin-button, .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-group.form-icon input::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-group.form-date::after {
  content: "";
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-group.form-zip-code::after {
  content: "";
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-ratio, .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-title, .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-address {
  @apply col-span-full;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-title {
  @apply font-normal text-lg leading-21px text-main-799167 md:text-white mt-5 mb-10px;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-ratio .wpcf7-radio, .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-ratio .wpcf7-checkbox {
  padding-left: 0.5208333333rem;
  @apply grid grid-cols-2 lg:gap-x-11 lg:gap-y-5 gap-10px;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-ratio .wpcf7-radio .wpcf7-list-item, .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-ratio .wpcf7-checkbox .wpcf7-list-item {
  @apply mx-0;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-ratio .wpcf7-radio .wpcf7-list-item > label > span, .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-ratio .wpcf7-checkbox .wpcf7-list-item > label > span {
  @apply font-normal text-sm leading-4 text-main-799167 md:text-white cursor-pointer flex items-baseline;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-ratio .wpcf7-radio .wpcf7-list-item > label > span::before, .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-ratio .wpcf7-checkbox .wpcf7-list-item > label > span::before {
  content: "";
  @apply font-light text-base left-4 font-Awesome6 transition-all mr-15px;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-ratio .wpcf7-radio .wpcf7-list-item > label input, .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-ratio .wpcf7-checkbox .wpcf7-list-item > label input {
  display: none;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-ratio .wpcf7-radio .wpcf7-list-item > label input:checked ~ span::before, .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .form-ratio .wpcf7-checkbox .wpcf7-list-item > label input:checked ~ span::before {
  content: "";
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .submit-button {
  @apply mt-60px;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .submit-button .btn-input {
  height: 2.6041666667rem;
  border-radius: 0.5208333333rem;
  padding: 0.2604166667rem 2.5520833333rem;
  @apply rounded-full text-white flex items-center relative bg-orange w-fit;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .submit-button .btn-input::before {
  content: "";
  font-weight: 300;
  font-size: 1.6666666667rem;
  line-height: 1.6666666667rem;
  @apply font-Awesome6 mr-10px;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .submit-button .btn-input::after {
  content: var(--data-title);
  @apply font-Philosopher font-bold text-xl leading-22px;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .submit-button .btn-input input[type=submit] {
  @apply opacity-0 w-full absolute top-0 left-0 h-full;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content form .wpcf7-response-output {
  height: fit-content !important;
  @apply mt-auto;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 {
  background: #D6DEE0;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .medium-title {
  @apply mb-13;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-1 {
  padding-bottom: 7.65625rem;
  @apply relative;
}

@media (min-width: 1280px) {
  .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-1::before {
    background-image: url(../img/home/popup_cloud_1.png);
    background-size: cover;
    height: 15.4166666667rem;
    content: "";
    bottom: -4.1666666667rem;
    @apply w-full absolute left-0;
  }
}
.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-1 .wrapper {
  max-width: 60.9375rem;
  @apply mx-auto;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .description {
  max-width: 52.6041666667rem;
  @apply mx-auto font-normal text-lg leading-21px text-main-435B5A mt-25px text-center;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .order-contact-button {
  border-radius: 5.2083333333rem;
  margin-bottom: 2.0833333333rem;
  @apply flex justify-center gap-10px mt-30px;
}

@media (min-width: 1280px) {
  .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .order-contact-button {
    margin-bottom: 4.1666666667rem;
  }
}
.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .order-contact-button .btn {
  width: 8.3333333333rem;
  height: 2.0833333333rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #F37926;
  @apply text-white font-Philosopher font-bold text-xl leading-22px flex items-center justify-center rounded-full mx-0;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .order-contact-button .btn:hover {
  @apply opacity-90;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .main .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 59.8290598291%;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .main .img a img, .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .main .img a iframe, .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .main .img a video, .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .main .img a canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .thumb {
  @apply mt-30px;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .thumb .title {
  @apply font-bold text-xl leading-23px text-justify text-main-435B5A;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .thumb .ctn {
  @apply font-normal text-base leading-19px text-justify text-main-435B5A mt-10px;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .swiper-btn-wrapper {
  width: 5.7291666667rem;
  height: 1.5625rem;
  border-radius: 5.2083333333rem;
  padding: 0.2604166667rem 0.78125rem;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2.0833333333rem;
  @apply absolute bg-main-435B5A/50 flex items-center justify-center z-10 select-none;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .swiper-btn-wrapper .swiper-btn {
  @apply h-full;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .swiper-btn-wrapper .swiper-pagination {
  font-size: 0;
  @apply static flex items-center justify-center h-full;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .swiper-btn-wrapper .swiper-pagination span {
  @apply border-none font-bold font-Philosopher text-lg leading-5 text-white h-auto mx-0;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .swiper-btn-wrapper .swiper-pagination span:first-child {
  margin-left: -0.2604166667rem;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .swiper-btn-wrapper .swiper-pagination span + span::before {
  content: "/";
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .swiper-btn-wrapper .swiper-button-prev::after, .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .swiper-btn-wrapper .swiper-button-next::after {
  @apply brightness-0 invert;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .swiper-btn-wrapper .swiper-button-prev {
  left: 0;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .swiper-btn-wrapper .swiper-button-next {
  right: 0;
}

@media (min-width: 1280px) {
  .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2.section {
    padding-top: 3.90625rem;
    padding-bottom: 15.625rem;
  }
}
.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form {
  @apply bg-main-C9D5C1;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form {
  padding: 15px 20px;
}

@media (min-width: 1280px) {
  .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form {
    padding: 1.5625rem 2.6041666667rem;
  }
}
.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row {
  @apply flex xl:flex-nowrap;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .ezbe-col-12 {
  flex: 0 0 33.333%;
  @apply relative;
}

@media (min-width: 576px) {
  .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .ezbe-col-12 {
    flex: 0 0 25%;
  }
}
@media (min-width: 1280px) {
  .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .ezbe-col-12 {
    flex: 0 0 calc( 1/4*50% );
  }
}
.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .ezbe-col-12:nth-of-type(2) {
  flex: 0 0 100%;
}

@media (min-width: 1280px) {
  .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .ezbe-col-12:nth-of-type(2) {
    flex: 0 0 50%;
  }
}
@media (max-width: 1279.98px) {
  .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .ezbe-col-12:nth-of-type(2) {
    @apply mb-15px;
  }
}
.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .ezbe-col-12:nth-of-type(2) input {
  @apply absolute w-full left-0 top-0 h-full opacity-0;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .ezbe-col-12:nth-of-type(6) {
  @apply hidden;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .ezbe-col-12:last-child {
  @apply flex items-end;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .big-number, .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .custom-select .value {
  font-size: 2.8125rem;
  line-height: 3.125rem;
  @apply font-Philosopher text-main-435B5A font-bold;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .custom-content-wrapper {
  @apply flex flex-col;
  @apply font-Philosopher text-lg leading-5;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .custom-content-wrapper.custom-select {
  @apply flex flex-col h-full relative xl:text-center;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .custom-content-wrapper.custom-select .title {
  @apply flex-1;
  @apply flex items-center xl:justify-center;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .custom-content-wrapper.custom-select .title::after {
  content: "";
  @apply ml-5px text-lg font-Awesome6 font-light;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .custom-content-wrapper.custom-select select {
  padding: 0 !important;
  @apply text-xl h-auto p-0 absolute w-full h-full top-0 left-0 opacity-0;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .custom-content-wrapper.custom-select select:focus {
  @apply outline-0;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .custom-content-wrapper .top {
  @apply flex items-center;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .custom-content-wrapper .top .value {
  flex: 0 0 3.125rem;
  @apply flex items-center;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .custom-content-wrapper .top .value::after {
  content: "";
  @apply ml-5px text-lg font-Awesome6 font-light;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .custom-content-wrapper .top .diffDay {
  border-radius: 0.2604166667rem;
  padding: 0.2604166667rem 0.5208333333rem;
  @apply bg-orange font-bold font-Philosopher text-white ml-5;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .custom-content-wrapper .top .diffDay em {
  @apply not-italic;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .custom-content-wrapper .bottom {
  @apply flex mt-15px xl:mt-5;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .custom-content-wrapper .bottom .date {
  @apply flex items-center;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .custom-content-wrapper .bottom .date + .date {
  @apply ml-13;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .custom-content-wrapper .bottom .date .right {
  @apply font-Philosopher font-normal text-main-435B5A ml-5;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .custom-content-wrapper .bottom .date .right .year {
  @apply mt-5px;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-row .custom-content-wrapper .order-title {
  @apply font-Philosopher font-bold text-main-435B5A;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form .ezbe-form .ezbe-form-control {
  border: 0 !important;
  @apply bg-transparent font-Philosopher font-normal text-lg leading-5 text-main-435B5A;
}

.custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form input.ezweb-form-submit {
  border-radius: 5.2083333333rem;
  height: 2.0833333333rem;
  @apply bg-orange font-Philosopher font-bold text-xl leading-22px text-white w-full;
}

@media (min-width: 1280px) {
  .custom-fancybox-popup.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content.popup-2 .section-2 .booking-form input.ezweb-form-submit {
    height: auto;
    padding: 0.46875rem 2.0833333333rem;
  }
}
body.homepage .daterangepicker {
  display: block !important;
  @apply mt-0 border-0 bg-transparent static w-full;
}

body.homepage .daterangepicker::before, body.homepage .daterangepicker::after {
  @apply hidden;
}

body.homepage .daterangepicker .datepicker-wrapper {
  @apply grid md:grid-cols-2 gap-0;
}

body.homepage .daterangepicker .datepicker-wrapper .ranges {
  @apply absolute top-0 left-0 pointer-events-none;
}

body.homepage .daterangepicker .datepicker-wrapper .drp-calendar {
  @apply float-none mx-0 max-w-full p-0;
}

body.homepage .daterangepicker .datepicker-wrapper .drp-calendar.left {
  background: #F9F9F9;
}

body.homepage .daterangepicker .datepicker-wrapper .drp-calendar.right {
  background: #F6F6F6;
}

body.homepage .daterangepicker .calendar-table {
  @apply bg-transparent border-0 pt-10 pb-20;
}

@media (max-width: 1279.98px) {
  body.homepage .daterangepicker .calendar-table {
    padding: 30px 10px;
  }
}
body.homepage .daterangepicker .table-condensed {
  @apply mx-auto text-center;
}

@media (min-width: 1280px) {
  body.homepage .daterangepicker .table-condensed {
    max-width: 21.875rem;
  }
}
body.homepage .daterangepicker .table-condensed thead tr:first-child th:nth-of-type(2) {
  padding: 0.5208333333rem 1.0416666667rem;
  @apply bg-white font-Philosopher font-bold text-2xl leading-27px text-main-435B5A;
}

body.homepage .daterangepicker .table-condensed thead tr:last-child {
  @apply font-Philosopher font-bold text-lg leading-5 text-main-435B5A;
}

body.homepage .daterangepicker .table-condensed thead tr:last-child th {
  @apply pt-15px font-Philosopher font-bold text-lg leading-5 text-main-435B5A;
}

body.homepage .daterangepicker .table-condensed tbody td {
  width: 2.9166666667rem;
  height: 2.9166666667rem;
  z-index: 1;
  @apply relative bg-transparent font-Philosopher font-bold text-lg leading-5 text-main-435B5A transition-all;
}

body.homepage .daterangepicker .table-condensed tbody td.off.disabled::before {
  @apply bg-999999/[.1];
}

body.homepage .daterangepicker .table-condensed tbody td.off.disabled.ends {
  text-decoration: auto;
  @apply opacity-70;
}

body.homepage .daterangepicker .table-condensed tbody td.off.disabled.ends::after {
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 50%;
  height: 1px;
  @apply absolute bg-999999;
}

@media (min-width: 1280px) {
  body.homepage .daterangepicker .table-condensed tbody td.off.disabled.ends::after {
    height: 0.0520833333rem;
  }
}
body.homepage .daterangepicker .table-condensed tbody td.off.disabled.disabled {
  @apply opacity-70;
}

body.homepage .daterangepicker .table-condensed tbody td.in-range {
  @apply text-white;
}

body.homepage .daterangepicker .table-condensed tbody td.in-range::before {
  @apply bg-orange/30;
}

body.homepage .daterangepicker .table-condensed tbody td.active {
  @apply text-white;
}

body.homepage .daterangepicker .table-condensed tbody td.active::before {
  @apply bg-orange;
}

body.homepage .daterangepicker .table-condensed tbody td::before {
  content: "";
  width: calc( 100% - 2.5/1920*100rem );
  height: calc( 100% - 2.5/1920*100rem );
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  @apply absolute bg-white transition-all;
}

.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide {
  @apply p-0;
}

.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content {
  padding-top: 3.75rem;
  padding-bottom: 3.125rem;
  padding-left: 0;
  padding-right: 0;
  @apply w-full bg-main-C9D5C1;
}

@media (min-width: 1280px) {
  .term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content {
    padding-top: 5.2083333333rem;
    padding-bottom: 14.1666666667rem;
  }
}
.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .carousel__button {
  width: 3.75rem;
  height: 3.75rem;
  right: 0;
  top: 0px;
  @apply bg-main-435B5A rounded-none transition-all;
}

.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .carousel__button i {
  font-size: 1.6666666667rem;
  @apply text-white font-light;
}

.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .carousel__button:hover {
  @apply bg-orange;
}

.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .logo-80, .term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .medium-title {
  @apply text-center;
}

.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .article {
  margin-top: 1.5625rem;
  padding-bottom: 20px;
  padding-top: 20px;
  @apply bg-white;
}

@media (min-width: 1280px) {
  .term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .article {
    padding-top: 3.6458333333rem;
    padding-bottom: 1.3020833333rem;
    margin-top: 3.3854166667rem;
  }
}
.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .article-content {
  @apply mx-auto px-15px lg:px-0;
}

@media (min-width: 1024px) {
  .term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .article-content {
    max-width: 60.9375rem;
  }
}
.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .wrapper + .wrapper {
  @apply mt-30px xl:mt-13;
}

.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .title {
  border-bottom: 1px solid #C9D5C1;
  @apply font-bold font-Philosopher text-xl leading-22px text-main-435B5A flex items-center pb-10px;
}

@media (min-width: 1280px) {
  .term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .title {
    border-bottom: 0.0520833333rem solid #C9D5C1;
  }
}
.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .title .id {
  flex: 0 0 2.0833333333rem;
  width: 2.0833333333rem;
  height: 2.0833333333rem;
  @apply rounded-full bg-main-C9D5C1 flex items-center justify-center mr-5;
}

.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .content {
  @apply mt-10px sm:mt-30px;
}

.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .content * {
  text-align: justify;
  @apply font-Philosopher font-normal text-lg leading-5;
}

.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .content table {
  @apply w-full;
}

.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .content table td {
  border: thin solid;
  @apply border-black py-5px px-10px;
}

.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .content strong {
  @apply font-bold;
}

.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .content * + * {
  @apply mt-15px;
}

.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .content ol {
  list-style: auto;
  padding-left: 17px;
}

@media (min-width: 1280px) {
  .term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .content ol {
    padding-left: 0.8854166667rem;
  }
}
.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .content ul {
  padding-left: 17px;
  @apply list-disc;
}

@media (min-width: 1280px) {
  .term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .content ul {
    padding-left: 0.8854166667rem;
  }
}
.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .read-more {
  margin-top: 1.5625rem;
  @apply flex items-center flex-col font-Philosopher font-bold text-xl leading-22px text-orange;
}

@media (min-width: 1280px) {
  .term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .read-more {
    margin-top: 3.75rem;
  }
}
.term-fancybox.fancybox__container .fancybox__viewport .fancybox__slide .fancybox__content .read-more i {
  font-size: 1.6666666667rem;
  animation: flowdinng 1.5s infinite alternate;
  @apply leading-32px mt-10px;
}

body.mac-user .custom-fancybox-popup .popup-2 .section-2 .custom-content-wrapper.custom-select select:focus {
  visibility: visible !important;
}

.search-page {
  background-color: #f0f0f2;
}

.search-page .search-form form {
  @apply relative;
}

.search-page .search-form form input {
  padding: 0.5208333333rem 1.0416666667rem;
  border: thin solid;
  padding-right: 2.34375rem;
  @apply w-full bg-transparent transition-all border-main-435B5A rounded-lg;
}

.search-page .search-form form input:not(:placeholder-shown) {
  @apply bg-main-799167/[.15] border-transparent;
}

.search-page .search-form form button {
  width: 2.0833333333rem;
  @apply h-full absolute top-0 right-0 flex items-center justify-center;
}

.search-page .search-query {
  @apply my-15px;
}

.search-page .found-nothing {
  background-image: url(../img/nothing.png);
  height: 30vh;
  @apply bg-no-repeat bg-center;
}

.search-page .found-nothing-title {
  @apply text-xl uppercase relative font-bold  text-main-435B5A;
}

@media (min-width: 1280px) {
  .initial-main #main-banner .img img {
    @apply scale-100;
  }

  .initial-main #main-banner .container {
    bottom: 6.25rem;
    @apply delay-500 opacity-100;
  }

  .initial-main #main-banner .content > div + div {
    @apply delay-750 scale-100;
  }

  .initial-main #main-banner .content .count-down .time {
    @apply scale-100 transition-all duration-500 opacity-100;
  }

  .initial-main #main-banner .content .count-down .time:nth-of-type(1) {
    @apply delay-1000;
  }

  .initial-main #main-banner .content .count-down .time:nth-of-type(2) {
    @apply delay-1100;
  }

  .initial-main #main-banner .content .count-down .time:nth-of-type(3) {
    @apply delay-1200;
  }

  .initial-main #main-banner .content .count-down .time:nth-of-type(4) {
    @apply delay-1300;
  }

  .initial-main #main-banner .swiper-pagination {
    @apply opacity-100 delay-1400 opacity-100;
  }
}
#main-banner .swiper .swiper-slide {
  @apply h-auto;
}

#main-banner .box-image {
  @apply h-full;
}

@media (max-width: 767.98px) {
  #main-banner .box-image {
    @apply flex flex-col h-full;
  }
}
#main-banner .img {
  @apply relative md:h-full;
}

@media (min-width: 768px) {
  #main-banner .img::before, #main-banner .img::after {
    content: "";
    @apply absolute w-full h-full top-0 left-0 z-5;
  }

  #main-banner .img::before {
    background: #F37926;
    opacity: 0.25;
  }

  #main-banner .img::after {
    background: #000000;
    mix-blend-mode: multiply;
    opacity: 0.25;
  }
}
#main-banner .img img {
  @apply w-full h-full object-cover;
}

@media (min-width: 1280px) {
  #main-banner .img img {
    width: 100%;
    height: 100vh;
    @apply scale-125 transition-all duration-1200;
  }
}
#main-banner .container {
  left: 50%;
  bottom: 30px;
  @apply static md:absolute bg-main-435B5A md:bg-transparent py-30px md:py-0 z-5 flex-1;
}

@media (min-width: 768px) {
  #main-banner .container {
    transform: translateX(-50%);
  }
}
@media (min-width: 1280px) {
  #main-banner .container {
    bottom: 0;
    @apply transition-all duration-1000 opacity-0;
  }
}
@media (max-width: 767.98px) {
  #main-banner .content {
    @apply flex flex-col h-full;
  }

  #main-banner .content .block-title {
    @apply flex-1;
  }
}
#main-banner .content > div + div {
  @apply mb-10px xl:mt-10 transition-all duration-500 scale-75;
}

#main-banner .content > div:last-child {
  @apply mb-0;
}

#main-banner .content .button .btn.btn-2 {
  width: 9.375rem;
}

#main-banner .content .logo img {
  height: 3.125rem;
  @apply object-contain;
}

@media (min-width: 1280px) {
  #main-banner .content .logo img {
    height: 5.2083333333rem;
  }
}
#main-banner .content .count-down {
  @apply flex items-center justify-center;
}

#main-banner .content .count-down .time {
  flex: 0 0 5.2083333333rem;
  width: 5.2083333333rem;
  height: 5.2083333333rem;
  @apply flex flex-col font-Philosopher font-normal relative items-center justify-center;
}

@media (min-width: 1280px) {
  #main-banner .content .count-down .time {
    flex: 0 0 5.5208333333rem;
    width: 5.5208333333rem;
    height: 5.5208333333rem;
    @apply scale-75 opacity-0;
  }
}
#main-banner .content .count-down .time + .time {
  @apply ml-30px;
}

#main-banner .content .count-down .time::before {
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-image: url(../img/banner/circle.svg);
  animation: spin_trcen 5s infinite linear;
  @apply bg-no-repeat bg-contain absolute w-full h-full pointer-events-none bg-center;
}

#main-banner .content .count-down .time:nth-of-type(2)::before {
  animation-delay: 0.5s;
}

#main-banner .content .count-down .time:nth-of-type(3)::before {
  animation-delay: 1s;
}

#main-banner .content .count-down .time:nth-of-type(4)::before {
  animation-delay: 1.5s;
}

#main-banner .content .count-down .time .value {
  font-size: 1.8229166667rem;
  line-height: normal;
  @apply font-normal;
}

@media (min-width: 576px) {
  #main-banner .content .count-down .time .value {
    font-size: 1.5625rem;
  }
}
@media (min-width: 1280px) {
  #main-banner .content .count-down .time .value {
    font-size: 2.0833333333rem;
    line-height: 2.34375rem;
  }
}
#main-banner .content .count-down .time .unit {
  font-size: 0.9375rem;
  line-height: 1.0416666667rem;
}

#main-banner .swiper-pagination {
  bottom: 10px !important;
  @apply opacity-0;
}

@media (min-width: 1280px) {
  #main-banner .swiper-pagination {
    bottom: 0.5208333333rem !important;
  }
}
@media (min-width: 1280px) {
  #main-banner .block-title {
    font-size: 2.0833333333rem;
    line-height: normal;
  }
}
.global-breadcrumb {
  @apply bg-background-F5F5F5;
}

.global-breadcrumb .rank-math-breadcrumb > p {
  @apply flex items-center flex-wrap;
}

.global-breadcrumb .rank-math-breadcrumb > p > * {
  @apply flex;
}

.global-breadcrumb .rank-math-breadcrumb span.separator {
  font-size: 0;
  @apply mx-10px flex items-center;
}

.global-breadcrumb .rank-math-breadcrumb span.separator::before {
  content: "";
  @apply text-222222 font-light text-base leading-4 font-Awesome6;
}

.global-breadcrumb .rank-math-breadcrumb a, .global-breadcrumb .rank-math-breadcrumb span {
  padding: 0.546875rem 0;
  max-height: 40px;
  @apply font-normal text-sm leading-4 text-222222 flex items-center;
}

.global-breadcrumb .rank-math-breadcrumb a:first-child, .global-breadcrumb .rank-math-breadcrumb span:first-child {
  font-size: 0;
}

.global-breadcrumb .rank-math-breadcrumb a:first-child::before, .global-breadcrumb .rank-math-breadcrumb span:first-child::before {
  content: "";
  @apply font-Awesome6 font-light text-base leading-4 text-222222;
}

.global-breadcrumb .rank-math-breadcrumb + li {
  @apply ml-15px pl-15px relative;
}

.global-breadcrumb .rank-math-breadcrumb + li::before {
  content: "";
  @apply font-Awesome6 absolute top-1/2 -translate-y-1/2 left-0 text-222222 text-base;
}

footer {
  border-top: thin solid #ccc;
  border-bottom: 0.5208333333rem solid #799167;
}

footer.section {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  padding-bottom: 10px;
}

@media (min-width: 1280px) {
  footer.section {
    padding-bottom: 1.0416666667rem;
    padding-left: 5.7291666667rem;
    padding-right: 5.7291666667rem;
    padding-top: 4.1666666667rem;
  }
}
footer .logo img {
  height: 8.8541666667rem;
  @apply object-contain;
}

footer .footer-wrapper {
  margin-bottom: 1.5625rem;
}

@media (min-width: 1024px) {
  footer .footer-wrapper {
    margin-bottom: 2.34375rem;
  }
}
footer .title {
  margin-bottom: 1.40625rem;
  @apply font-Philosopher font-bold text-2xl leading-27px text-main-435B5A;
}

footer .ctn {
  @apply font-normal text-lg leading-22px text-333333;
}

@media (max-width: 1023.98px) {
  footer .box-info {
    @apply text-center;
  }
}
@media (max-width: 1023.98px) {
  footer .box-info:nth-of-type(1) {
    order: 2;
  }
}
footer .box-info:nth-of-type(2) {
  @apply text-center;
}

@media (max-width: 1023.98px) {
  footer .box-info:nth-of-type(2) {
    order: 1;
    @apply col-span-full;
  }
}
@media (max-width: 1023.98px) {
  footer .box-info:nth-of-type(3) {
    order: 3;
  }
}
@media (min-width: 1024px) {
  footer .box-info:nth-of-type(3) .wrapper {
    max-width: 18.2291666667rem;
    @apply ml-auto;
  }
}
footer .social-icons {
  @apply flex items-center mb-5 justify-center lg:justify-start;
}

footer .social-icons a {
  border: 0.0520833333rem solid #435B5A;
  width: 2.34375rem;
  height: 2.34375rem;
  @apply rounded-full flex items-center justify-center transition-all;
}

footer .social-icons a:hover {
  @apply bg-main-435B5A shadow-lg shadow-main-435B5A;
}

footer .social-icons a:hover i {
  @apply text-white;
}

footer .social-icons a + a {
  @apply ml-5 sm:ml-30px;
}

footer .social-icons i {
  @apply text-2xl text-main-435B5A font-normal transition-all;
}

footer .bct {
  @apply mt-5;
}

footer .bct img {
  width: 8.5416666667rem;
  height: 3.125rem;
  @apply object-contain;
}

footer form input {
  height: 2.6041666667rem;
  @apply w-full;
}

footer form .form-group input {
  background: #F9F9F9;
  padding: 0.5208333333rem 1.5625rem;
  @apply rounded-full font-normal text-base text-main-435B5A mb-10px;
}

footer form .btn-input {
  height: 2.6041666667rem;
  width: 100% !important;
  @apply bg-orange rounded-full text-white flex items-center justify-center relative;
}

footer form .btn-input::before {
  content: "";
  font-weight: 300;
  font-size: 1.6666666667rem;
  line-height: 1.6666666667rem;
  @apply font-Awesome6 mr-10px;
}

footer form .btn-input::after {
  content: var(--data-title);
  @apply font-Philosopher font-bold text-xl leading-22px;
}

footer form .btn-input input[type=submit] {
  @apply opacity-0 w-full absolute top-0 left-0 h-full;
}

footer form .btn-input .wpcf7-spinner {
  left: unset !important;
  right: 100% !important;
}

footer .bottom {
  border-top: 1px solid #D6DEE0;
  @apply pt-15px mt-5;
}

footer .bottom ul {
  @apply flex items-center justify-center flex-wrap;
}

footer .bottom ul li {
  @apply relative text-333333 font-normal text-base leading-19px transition-all;
}

@media (max-width: 767.98px) {
  footer .bottom ul li {
    @apply text-center;
  }

  footer .bottom ul li:first-child {
    flex: 0 0 100%;
  }
}
footer .bottom ul li:hover {
  @apply text-orange;
}

footer .bottom ul li + li {
  @apply ml-5px md:ml-10px pl-5px md:pl-10px;
}

@media (min-width: 768px) {
  footer .bottom ul li + li::before {
    content: "";
    height: 1.0416666667rem;
    width: 1px;
    @apply absolute top-1/2 -translate-y-1/2 left-0 bg-333333;
  }
}
@media (min-width: 768px) and (min-width: 1280px) {
  footer .bottom ul li + li::before {
    width: 0.0520833333rem;
  }
}
.fa-square-facebook::before {
  content: "";
}

header {
  z-index: 150;
  @apply fixed top-0 left-0 w-full transition-all;
}

@media (min-width: 1280px) {
  header {
    @apply -translate-y-full;
  }
}
header.header-scroll .top {
  @apply duration-500;
}

@media (min-width: 1280px) {
  header.header-scroll .top {
    margin-top: -2.0833333333rem;
  }
}
header.initial-header {
  @apply translate-y-0 duration-500 delay-700;
}

header .top {
  padding: 0.5208333333rem 0.78125rem;
  height: 50px;
  @apply bg-main-C9D5C1 transition-all relative flex items-center justify-between xl:justify-end;
}

@media (min-width: 576px) {
  header .top {
    height: 60px;
  }
}
@media (min-width: 1280px) {
  header .top {
    height: 2.0833333333rem;
    padding: 0.3125rem 3.6458333333rem;
  }
}
header .top.search-active {
  height: auto;
}

@media (max-width: 1279.98px) {
  header .top.search-active .hotline {
    @apply opacity-0 pointer-events-none w-0;
  }
}
@media (max-width: 767.98px) {
  header .top.search-active .language {
    @apply opacity-0 pointer-events-none w-0;
  }
}
header .top .tools {
  @apply flex items-center justify-end;
}

header .top .hotline {
  @apply font-Philosopher font-bold text-base leading-18px text-main-435B5A whitespace-nowrap transition-all flex items-center;
}

header .top .hotline a {
  @apply flex items-center;
}

@media (max-width: 767.98px) {
  header .top .hotline {
    font-size: 0;
  }
}
header .top .hotline i {
  @apply text-base leading-4 font-light;
}

@media (max-width: 767.98px) {
  header .top .hotline i {
    @apply text-base;
  }
}
header .top .hotline span {
  z-index: 1;
  width: 30px;
  height: 30px;
  @apply relative flex items-center justify-center text-white md:mr-10px;
}

@media (min-width: 768px) {
  header .top .hotline span {
    width: 1.5625rem;
    height: 1.5625rem;
  }
}
header .top .hotline span::before {
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  @apply bg-main-799167 rounded-full absolute w-full h-full w-full h-full;
}

header .top .hotline span::after {
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc( 100% + 10/1920*100rem);
  height: calc( 100% + 10/1920*100rem);
  background: rgba(121, 145, 103, 0.35);
  z-index: -1;
  animation: heartbeat 1.5s ease-in-out infinite both;
  @apply rounded-full absolute;
}

header .top .language {
  @apply ml-10px md:ml-5 xl:ml-10 transition-all hidden;
}

header .top .language .wpml-ls {
  border: 0;
}

header .top .language ul {
  @apply flex items-center;
}

header .top .language li {
  @apply flex items-center;
}

header .top .language li + li {
  @apply ml-10px;
}

header .top .language li a {
  @apply flex items-center p-0;
}

header .top .language li a span {
  @apply font-Philosopher font-bold text-base leading-4 text-main-435B5A;
}

header .top .language li.wpml-ls-current-language span {
  border-bottom: 0.0520833333rem solid;
  @apply border-b-orange;
}

header .top .search {
  display: none !important;
  @apply text-main-435B5A ml-10px md:ml-5 xl:ml-10;
}

header .top .search i {
  @apply text-base font-light leading-4;
}

header .top .search .search-overlay input {
  width: 2.6041666667rem;
  height: 1.4583333333rem;
  border-radius: 5.2083333333rem;
  padding-left: 1.0416666667rem;
  padding-right: 1.8229166667rem;
  @apply transition-all border-none outline-none placeholder:text-transparent transition-all;
}

header .top .search .search-overlay button {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @apply absolute transition-all pointer-events-none;
}

header .top .search .search-overlay.active input {
  height: 1.5625rem;
  width: 11.9791666667rem;
}

@media (min-width: 576px) {
  header .top .search .search-overlay.active input {
    height: 2.0833333333rem;
    width: 13.0208333333rem;
  }
}
@media (min-width: 768px) {
  header .top .search .search-overlay.active input {
    width: 18.2291666667rem;
  }
}
@media (min-width: 1280px) {
  header .top .search .search-overlay.active input {
    height: 2.6041666667rem;
    width: 20.8333333333rem;
  }
}
header .top .search .search-overlay.active button {
  left: calc( 100% - 30/1920*100rem );
  transform: translate(0, -50%);
  @apply pointer-events-auto delay-200;
}

header .logo {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @apply absolute z-10;
}

header .logo img {
  height: 40px;
  @apply object-contain;
}

@media (min-width: 576px) {
  header .logo img {
    height: 50px;
  }
}
@media (min-width: 1280px) {
  header .logo img {
    height: 3.9583333333rem;
  }
}
header .bottom {
  padding: 0 3.6458333333rem;
  @apply flex items-center justify-between relative bg-white shadow-lg;
}

header .bottom nav {
  @apply hidden;
}

@media (min-width: 1280px) {
  header .bottom nav {
    @apply block;
  }
}
header .bottom nav > ul {
  gap: 3.6458333333rem;
  @apply flex items-center;
}

header .bottom nav > ul > li.active > a, header .bottom nav > ul > li[class*=current-] > a {
  @apply text-main-435B5A;
}

header .bottom nav > ul > li.active > a::before, header .bottom nav > ul > li[class*=current-] > a::before {
  @apply w-full;
}

header .bottom nav > ul > li > a {
  padding: 1.6145833333rem 0;
  @apply block font-Philosopher font-normal text-lg leading-5 text-333333 relative;
}

header .bottom nav > ul > li > a::before {
  content: "";
  height: 0.15625rem;
  left: 50%;
  transform: translateX(-50%);
  @apply absolute top-0 w-0 bg-orange transition-all;
}

header .burger i {
  @apply text-3xl transition-all duration-500;
}

header .burger i:nth-of-type(2) {
  left: 0%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1.1);
  @apply absolute opacity-0;
}

header .burger.active i:nth-of-type(1) {
  @apply opacity-0 scale-75;
}

header .burger.active i:nth-of-type(2) {
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  @apply opacity-100;
}

header .nav-mobile {
  top: var(--header-height);
  height: calc( 100vh - var(--header-height));
  transition: all 0.75s cubic-bezier(0.85, 0, 0.15, 1);
  max-width: 100%;
  @apply fixed w-full left-0 bg-gradient-to-b from-main-C9D5C1 to-main-799167 overflow-auto -left-full;
}

@media (min-width: 576px) {
  header .nav-mobile {
    max-width: 320px;
  }
}
header .nav-mobile.active {
  @apply left-0;
}

header .nav-mobile.active .nav-mobile-wrapper::before {
  bottom: 10px;
  @apply opacity-50 delay-500;
}

header .nav-mobile.active .nav-mobile-wrapper nav > ul > li {
  @apply opacity-100 translate-x-0;
}

header .nav-mobile .nav-mobile-wrapper {
  padding: 1.0416666667rem 1.5625rem;
  @apply h-full overflow-hidden;
}

header .nav-mobile .nav-mobile-wrapper::before {
  content: "";
  background-image: url(../img/logo_no_text.svg);
  background-size: contain;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10%;
  width: 14.5833333333rem;
  height: 14.5833333333rem;
  z-index: 1;
  @apply absolute opacity-0 pointer-events-none bg-no-repeat bg-center transition-all duration-300;
}

@media (min-width: 576px) {
  header .nav-mobile .nav-mobile-wrapper::before {
    width: 14.5833333333rem;
    height: 14.5833333333rem;
  }
}
header .nav-mobile nav > ul {
  @apply flex flex-col;
}

header .nav-mobile nav > ul > li {
  @apply w-fit opacity-0 -translate-x-5 transition-all duration-500;
}

header .nav-mobile nav > ul > li.active > a, header .nav-mobile nav > ul > li:hover > a, header .nav-mobile nav > ul > li[class*=current-] > a {
  @apply text-main-435B5A;
}

header .nav-mobile nav > ul > li.active > a::before, header .nav-mobile nav > ul > li:hover > a::before, header .nav-mobile nav > ul > li[class*=current-] > a::before {
  @apply w-full;
}

header .nav-mobile nav > ul > li > a {
  padding: 0.5208333333rem 0;
  @apply block font-Philosopher font-normal text-2xl sm:text-lg leading-5 text-333333 relative w-fit;
}

@media (min-width: 576px) {
  header .nav-mobile nav > ul > li > a {
    padding: 0.2604166667rem 0;
  }
}
header .nav-mobile nav > ul > li > a::before {
  content: "";
  height: 0.0520833333rem;
  left: 50%;
  transform: translateX(-50%);
  @apply absolute bottom-0 w-0 bg-orange transition-all;
}

main {
  margin-top: var(--header-height, 0);
}

@media (min-width: 1280px) {
  .homepage main {
    margin-top: 0;
  }
}
@media (min-width: 1280px) {
  .index-1 .container.section {
    padding-top: 8.0729166667rem;
    padding-bottom: 8.0729166667rem;
  }
}
.index-1 .image-cover {
  @apply absolute w-full h-full top-0 left-0 select-none;
}

.index-1 .image-cover [class*=cloud-] {
  top: 2.0833333333rem;
  @apply absolute z-3;
}

.index-1 .image-cover [class*=cloud-] img {
  @apply w-full h-full object-contain;
}

.index-1 .image-cover .cloud-1 {
  width: 40%;
  @apply left-0;
}

@media (min-width: 1280px) {
  .index-1 .image-cover .cloud-1 {
    width: 37.0833333333rem;
    height: 13.3854166667rem;
  }
}
.index-1 .image-cover .cloud-1 img {
  animation: animateReversedLeftCloud 5s cubic-bezier(0.645, 0.045, 0.355, 1) infinite alternate;
}

.index-1 .image-cover .cloud-2 {
  width: 70%;
  @apply right-0;
}

@media (min-width: 1280px) {
  .index-1 .image-cover .cloud-2 {
    width: 74.21875rem;
    height: 24.7395833333rem;
  }
}
.index-1 .image-cover .cloud-2 img {
  animation: animateReversedLeftCloud 5s cubic-bezier(0.645, 0.045, 0.355, 1) infinite alternate;
}

.index-1 .image-cover .background {
  @apply w-full h-full;
}

.index-1 .image-cover .background img {
  @apply w-full h-full object-cover;
}

.index-1 .image-cover::before {
  content: "";
  background: linear-gradient(167.06deg, rgba(0, 0, 0, 0) 56.6%, rgba(0, 0, 0, 0.2) 63.95%, rgba(0, 0, 0, 0.2) 73.74%);
  background-blend-mode: multiply, normal;
  mix-blend-mode: multiply;
  @apply absolute w-full h-full top-0 left-0;
}

.index-1 .image-cover::after {
  content: "";
  background: #D6DEE0;
  background-blend-mode: multiply, normal;
  mix-blend-mode: multiply;
  @apply absolute w-full h-full top-0 left-0;
}

.index-1 .box-left {
  @apply flex flex-col;
}

.index-1 .box-left .logo-80 {
  @apply mb-5 xl:mb-8;
}

@media (max-width: 1279.98px) {
  .index-1 .box-left .logo-80 {
    order: 2;
  }
}
@media (max-width: 575.98px) {
  .index-1 .box-left .logo-80 {
    @apply hidden;
  }
}
.index-1 .box-left .title-wrapper {
  @apply xl:mb-8;
}

@media (max-width: 1279.98px) {
  .index-1 .box-left .title-wrapper {
    order: 3;
  }
}
.index-1 .box-left .title-wrapper .block-title + .block-title {
  @apply xl:mt-10px;
}

.index-1 .box-left .content {
  max-width: 32.5520833333rem;
  @apply font-normal text-333333 mt-30px xl:mt-8 text-lg leading-normal;
}

@media (max-width: 1279.98px) {
  .index-1 .box-left .content {
    order: 4;
  }
}
.index-1 .box-left .content * + * {
  @apply mt-30px lg:mt-9;
}

.index-1 .box-left .image-wrapper {
  max-width: 41.6666666667rem;
  margin: 0 auto;
  margin-top: 1.5625rem;
  @apply w-full;
}

@media (min-width: 1280px) {
  .index-1 .box-left .image-wrapper {
    margin-top: 4.0104166667rem;
    padding-right: 1.5625rem;
    @apply mx-0;
  }
}
@media (max-width: 1279.98px) {
  .index-1 .box-left .image-wrapper {
    order: 1;
  }
}
.index-1 .box-left .image-wrapper .img {
  flex: 0 0 calc( 50% - 15/1920*100rem );
}

@media (min-width: 768px) {
  .index-1 .box-left .image-wrapper .img {
    flex: 0 0 17.1875rem;
  }
}
.index-1 .box-left .image-wrapper .img:nth-of-type(1) a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 117.0212765957%;
}

.index-1 .box-left .image-wrapper .img:nth-of-type(1) a img, .index-1 .box-left .image-wrapper .img:nth-of-type(1) a iframe, .index-1 .box-left .image-wrapper .img:nth-of-type(1) a video, .index-1 .box-left .image-wrapper .img:nth-of-type(1) a canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.index-1 .box-left .image-wrapper .img:nth-of-type(2) {
  margin-left: 1.5625rem;
  padding-top: 4.4270833333rem;
  flex: 0 0 calc( 50% - 15/1920*100rem );
}

@media (min-width: 768px) {
  .index-1 .box-left .image-wrapper .img:nth-of-type(2) {
    flex: 0 0 20.8333333333rem;
  }
}
@media (min-width: 1280px) {
  .index-1 .box-left .image-wrapper .img:nth-of-type(2) {
    margin-left: auto;
  }
}
.index-1 .box-left .image-wrapper .img:nth-of-type(2) a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 127.5%;
}

.index-1 .box-left .image-wrapper .img:nth-of-type(2) a img, .index-1 .box-left .image-wrapper .img:nth-of-type(2) a iframe, .index-1 .box-left .image-wrapper .img:nth-of-type(2) a video, .index-1 .box-left .image-wrapper .img:nth-of-type(2) a canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.index-1 .box-right {
  @apply mt-30px xl:mt-0;
}

@media (min-width: 1280px) {
  .index-1 .box-right .wrapper {
    margin-left: -0.5729166667rem;
  }
}
.index-1 .box-right .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 50%;
}

.index-1 .box-right .img a img, .index-1 .box-right .img a iframe, .index-1 .box-right .img a video, .index-1 .box-right .img a canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

@media (min-width: 1024px) {
  .index-1 .box-right .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 75%;
  }

  .index-1 .box-right .img a img, .index-1 .box-right .img a iframe, .index-1 .box-right .img a video, .index-1 .box-right .img a canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
@media (min-width: 1280px) {
  .index-1 .box-right .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 115.8862876254%;
  }

  .index-1 .box-right .img a img, .index-1 .box-right .img a iframe, .index-1 .box-right .img a video, .index-1 .box-right .img a canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
.index-1 .box-right .content {
  margin-top: 1.5625rem;
  @apply font-normal text-lg leading-21px text-white;
}

@media (min-width: 1280px) {
  .index-1 .box-right .content {
    margin-top: 4.4270833333rem;
  }
}
.index-1 .box-right .content * + * {
  @apply mt-30px lg:mt-9;
}

.index-2 {
  background: #EAFAFE;
}

.index-2 .image-cover {
  @apply absolute bottom-0 left-0 w-full h-full;
}

.index-2 .image-cover img {
  @apply w-full h-full object-contain object-bottom;
}

.index-2 .absolute-logo {
  width: 25vw;
  height: 25vw;
  top: 25%;
  right: 0.5208333333rem;
  @apply absolute opacity-10;
}

@media (min-width: 1280px) {
  .index-2 .absolute-logo {
    right: 1.0416666667rem;
    top: 21%;
    width: 23.4661458333rem;
    height: 23.4104166667rem;
  }
}
.index-2 .absolute-logo img {
  @apply w-full h-full object-contain;
}

.index-2 .button {
  @apply mb-15px mt-5;
}

@media (min-width: 1280px) {
  .index-2 .container.section {
    padding-top: 5.2083333333rem;
    padding-bottom: 24.4791666667rem;
  }
}
.index-2 .description {
  max-width: 52.5rem;
  @apply font-normal text-lg leading-21px text-333333 text-justify md:text-center;
}

.index-2 .time-line-tree {
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  @apply font-Philosopher relative;
}

@media (min-width: 768px) {
  .index-2 .time-line-tree {
    padding-top: 2.8645833333rem;
    padding-bottom: 5.2083333333rem;
    padding-left: 0;
  }
}
@media (min-width: 1280px) {
  .index-2 .time-line-tree {
    padding-bottom: 12.2395833333rem;
  }
}
.index-2 .time-line-tree::before {
  content: "";
  width: 0.0520833333rem;
  top: 0;
  left: 15px;
  height: 100%;
  border: thin dashed #C9D5C1;
  @apply absolute;
}

@media (min-width: 768px) {
  .index-2 .time-line-tree::before {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 1280px) {
  .index-2 .time-line-tree::before {
    height: var(--data-percent);
    border: 0.0520833333rem dashed #C9D5C1;
  }
}
.index-2 .time-line-tree .grid {
  padding-left: 35px;
  @apply will-change-transform;
}

@media (min-width: 768px) {
  .index-2 .time-line-tree .grid {
    padding-left: 0;
  }
}
.index-2 .time-line-tree .grid + .grid {
  margin-top: 3.6458333333rem;
}

.index-2 .time-line-tree .id {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  @apply text-white font-bold text-base leading-18px absolute;
}

@media (min-width: 768px) {
  .index-2 .time-line-tree .id {
    left: 50%;
  }
}
.index-2 .time-line-tree .id span {
  z-index: 1;
  width: 30px;
  height: 30px;
  @apply relative flex items-center justify-center text-white;
}

@media (min-width: 768px) {
  .index-2 .time-line-tree .id span {
    width: 2.0833333333rem;
    height: 2.0833333333rem;
  }
}
.index-2 .time-line-tree .id span::before {
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  @apply bg-orange rounded-full absolute w-full h-full w-full h-full;
}

.index-2 .time-line-tree .id span::after {
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc( 100% + 20/1920*100rem);
  height: calc( 100% + 20/1920*100rem);
  background: rgba(243, 121, 38, 0.35);
  z-index: -1;
  animation: heartbeat 1.5s ease-in-out infinite both;
  @apply rounded-full absolute;
}

.index-2 .time-line-tree .time {
  @apply font-bold text-lg leading-5 text-main-435B5A;
}

.index-2 .time-line-tree .content {
  border-radius: 0.5208333333rem;
  padding: 0.5208333333rem 1.0416666667rem;
  @apply font-bold text-xl leading-22px text-left text-white bg-orange flex items-center;
}

@media (min-width: 768px) {
  .index-2 .time-line-tree .content {
    margin-left: 2.0833333333rem;
  }
}
@media (min-width: 1280px) {
  .index-2 .time-line-tree .content {
    margin-left: 3.125rem;
  }
}
.index-2 .time-line-tree .time-line {
  margin-left: 5px;
  @apply flex items-center md:justify-end relative;
}

@media (min-width: 768px) {
  .index-2 .time-line-tree .time-line {
    margin-left: unset;
    margin-right: 2.0833333333rem;
    padding-right: 3.3854166667rem;
  }
}
.index-2 .time-line-tree .time-line::before {
  content: "";
  position: absolute;
  width: 2.1875rem;
  height: 0.0520833333rem;
  left: 120%;
  @apply absolute bg-orange;
}

@media (min-width: 768px) {
  .index-2 .time-line-tree .time-line::before {
    left: unset;
    right: 0;
  }
}
.index-2 .time-line-tree .time-line .time {
  padding: 0.2604166667rem 1.0416666667rem 0.2604166667rem 1.0416666667rem;
  @apply relative text-left;
}

@media (min-width: 768px) {
  .index-2 .time-line-tree .time-line .time {
    padding: 0.2604166667rem 1.0416666667rem 0.2604166667rem 2.34375rem;
  }
}
.index-2 .time-line-tree .time-line .time::before, .index-2 .time-line-tree .time-line .time::after {
  content: "";
  left: 0;
  height: 50%;
  width: 100%;
  background: white;
  z-index: -2;
  @apply absolute;
}

.index-2 .time-line-tree .time-line .time::before {
  top: 0;
  transform: skew(50deg);
}

.index-2 .time-line-tree .time-line .time::after {
  bottom: 0;
  transform: skew(-50deg);
}

.index-2 .time-line-tree .time-line .time:nth-of-type(odd)::before, .index-2 .time-line-tree .time-line .time:nth-of-type(odd)::after {
  @apply bg-white;
}

.index-2 .time-line-tree .time-line .time:nth-of-type(even)::before, .index-2 .time-line-tree .time-line .time:nth-of-type(even)::after {
  @apply bg-main-C9D5C1;
}

.index-3 .pattern-cover {
  z-index: 0;
  @apply absolute w-full h-full top-0 left-0 bg-333333;
}

.index-3 .pattern-cover::before {
  content: "";
  z-index: 2;
  background: #435B5A;
  mix-blend-mode: color;
  opacity: 0.75;
  @apply absolute w-full h-full top-0 left-0;
}

.index-3 .pattern-cover::after {
  content: "";
  z-index: 2;
  background: #fff;
  opacity: 0.3;
  mix-blend-mode: soft-light;
  @apply absolute w-full h-full top-0 left-0;
}

@media (min-width: 1280px) {
  .index-3 .container.section {
    padding-top: 6.7708333333rem;
    padding-bottom: 8.3333333333rem;
  }
}
.index-3 .logo img {
  height: 4.1666666667rem;
  @apply object-contain;
}

.index-3 .news-list-grid {
  margin-top: 1.5625rem;
  @apply grid grid-cols-2 md:grid-cols-3 xl:gap-30px lg:gap-10px gap-5px;
}

@media (min-width: 1280px) {
  .index-3 .news-list-grid {
    grid-template-rows: 16.1458333333rem 7.03125rem 16.1458333333rem;
    margin-top: 3.90625rem;
  }
}
@media (max-width: 767.98px) {
  .index-3 .news-list-grid {
    @apply hidden;
  }
}
@media (min-width: 768px) {
  .index-3 .news-list-grid [class*=bn-] .img {
    @apply h-full;
  }

  .index-3 .news-list-grid [class*=bn-] .img a {
    height: 100% !important;
  }

  .index-3 .news-list-grid [class*=bn-]:nth-of-type(1) {
    grid-area: 1/1/2/2;
  }

  .index-3 .news-list-grid [class*=bn-]:nth-of-type(1) .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 68.8888888889%;
  }

  .index-3 .news-list-grid [class*=bn-]:nth-of-type(1) .img a img, .index-3 .news-list-grid [class*=bn-]:nth-of-type(1) .img a iframe, .index-3 .news-list-grid [class*=bn-]:nth-of-type(1) .img a video, .index-3 .news-list-grid [class*=bn-]:nth-of-type(1) .img a canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }

  .index-3 .news-list-grid [class*=bn-]:nth-of-type(2) {
    grid-area: 1/2/3/4;
  }

  .index-3 .news-list-grid [class*=bn-]:nth-of-type(2) .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 51.0752688172%;
  }

  .index-3 .news-list-grid [class*=bn-]:nth-of-type(2) .img a img, .index-3 .news-list-grid [class*=bn-]:nth-of-type(2) .img a iframe, .index-3 .news-list-grid [class*=bn-]:nth-of-type(2) .img a video, .index-3 .news-list-grid [class*=bn-]:nth-of-type(2) .img a canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }

  .index-3 .news-list-grid [class*=bn-]:nth-of-type(3) {
    grid-area: 2/1/4/2;
  }

  .index-3 .news-list-grid [class*=bn-]:nth-of-type(3) .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 105.5555555556%;
  }

  .index-3 .news-list-grid [class*=bn-]:nth-of-type(3) .img a img, .index-3 .news-list-grid [class*=bn-]:nth-of-type(3) .img a iframe, .index-3 .news-list-grid [class*=bn-]:nth-of-type(3) .img a video, .index-3 .news-list-grid [class*=bn-]:nth-of-type(3) .img a canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }

  .index-3 .news-list-grid [class*=bn-]:nth-of-type(4) {
    grid-area: 3/2/4/3;
  }

  .index-3 .news-list-grid [class*=bn-]:nth-of-type(4) .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 68.8888888889%;
  }

  .index-3 .news-list-grid [class*=bn-]:nth-of-type(4) .img a img, .index-3 .news-list-grid [class*=bn-]:nth-of-type(4) .img a iframe, .index-3 .news-list-grid [class*=bn-]:nth-of-type(4) .img a video, .index-3 .news-list-grid [class*=bn-]:nth-of-type(4) .img a canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }

  .index-3 .news-list-grid [class*=bn-]:nth-of-type(5) {
    grid-area: 3/3/4/4;
  }

  .index-3 .news-list-grid [class*=bn-]:nth-of-type(5) .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 68.8888888889%;
  }

  .index-3 .news-list-grid [class*=bn-]:nth-of-type(5) .img a img, .index-3 .news-list-grid [class*=bn-]:nth-of-type(5) .img a iframe, .index-3 .news-list-grid [class*=bn-]:nth-of-type(5) .img a video, .index-3 .news-list-grid [class*=bn-]:nth-of-type(5) .img a canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
@media (min-width: 1280px) {
  .index-3 .news-list-grid .bn {
    transform: translateY(calc( 100% - var(--data-percent,0)));
    opacity: var(--data-percent, 0);
  }
}
@media (max-width: 767.98px) {
  .index-3 .news-list-grid .bn .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 68.8888888889%;
  }

  .index-3 .news-list-grid .bn .img a img, .index-3 .news-list-grid .bn .img a iframe, .index-3 .news-list-grid .bn .img a video, .index-3 .news-list-grid .bn .img a canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
.index-3 .news-list-grid .bn.done-animation {
  transform: translateY(0);
  @apply opacity-100;
}

.index-3 .news-list-grid .bn.done-animation .img::before {
  @apply opacity-0;
}

@media (min-width: 768px) {
  .index-3 .index-3-slide {
    @apply hidden;
  }
}
.index-3 .index-3-slide .swiper-pagination {
  bottom: -15px !important;
}

.index-3 .index-3-slide .bn .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 68.8888888889%;
}

.index-3 .index-3-slide .bn .img a img, .index-3 .index-3-slide .bn .img a iframe, .index-3 .index-3-slide .bn .img a video, .index-3 .index-3-slide .bn .img a canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.index-5.section {
  padding-bottom: 10.4166666667rem;
}

@media (min-width: 1280px) {
  .index-5.section {
    padding-top: 6.7708333333rem;
    padding-bottom: 22.7604166667rem;
  }
}
.index-5 .index-5-slide {
  max-width: 60.9375rem;
  margin: 0 auto;
  margin-top: 1.5625rem;
}

@media (min-width: 1280px) {
  .index-5 .index-5-slide {
    margin-top: 2.8645833333rem;
  }
}
.index-5 .index-5-slide .icon {
  width: 10.8333333333rem;
  @apply mx-auto;
}

.index-5 .index-5-slide .icon a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  @apply bg-white rounded-full;
}

.index-5 .index-5-slide .icon a img, .index-5 .index-5-slide .icon a iframe, .index-5 .index-5-slide .icon a video, .index-5 .index-5-slide .icon a canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: contain;
  transition: 0.3s ease-in-out all;
}

.index-5 .index-5-slide .icon a img {
  padding: 5%;
  max-height: 6.25rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 1280px) {
  .index-4.section {
    padding-top: 6.5104166667rem;
    padding-bottom: 3.90625rem;
  }
}
.index-4 .des, .index-4 .tips {
  max-width: 52.6041666667rem;
  @apply mx-auto;
}

@media (max-width: 1023.98px) {
  .index-4 .des, .index-4 .tips {
    @apply text-justify;
  }
}
.index-4 .des {
  @apply text-main-435B5A font-bold text-lg leading-normal mb-25px mt-25px;
}

.index-4 .tips {
  @apply font-normal text-xl leading-normal text-orange italic;
}

.index-4 .tips strong {
  @apply not-italic;
}

.index-4 .index-4-slide {
  margin-top: 1.5625rem;
}

@media (min-width: 1280px) {
  .index-4 .index-4-slide {
    margin-top: 5.2083333333rem;
  }
}
@media (min-width: 1280px) {
  .index-4 .index-4-slide .swiper {
    margin: 0 -2.109375rem;
  }
}
.index-4 .index-4-slide .swiper .swiper-wrapper {
  padding-bottom: 1.5625rem;
}

@media (min-width: 1280px) {
  .index-4 .index-4-slide .swiper .swiper-wrapper {
    height: 37.65625rem;
  }
}
.index-4 .index-4-slide .swiper .swiper-slide {
  @apply h-auto;
}

@media (max-width: 575.98px) {
  .index-4 .index-4-slide .swiper .swiper-slide .bn.order-contact-box.stay-hover {
    padding-bottom: 0;
  }
}
.index-4 .index-4-slide .swiper-pagination.swiper-pagination-fraction {
  font-size: 0;
  bottom: 0 !important;
  gap: 0.1041666667rem;
  @apply font-Philosopher font-bold leading-5 text-main-435B5A flex items-center justify-center h-full;
}

.index-4 .index-4-slide .swiper-pagination.swiper-pagination-fraction span {
  margin: 0;
  border: 0;
  @apply text-lg h-fit w-auto;
}

.index-4 .index-4-slide .swiper-pagination.swiper-pagination-fraction span.swiper-pagination-current::after {
  content: "/";
}

.index-4 .index-4-slide .swiper-btn {
  width: 8.3333333333rem;
  margin: 0 auto;
  height: 30px;
}

@media (min-width: 1280px) {
  .index-4 .index-4-slide .swiper-btn {
    height: 1.5625rem;
  }
}
.index-6.section {
  background: linear-gradient(180deg, rgba(234, 250, 254, 0) 0%, #EAFAFE 45.31%);
  padding-bottom: 17.7083333333rem;
}

@media (min-width: 1280px) {
  .index-6.section {
    padding-top: 0;
    margin-top: -3.3333333333rem;
    padding-bottom: 16.9270833333rem;
  }
}
.index-6.section::before {
  content: "";
  width: 32.3958333333rem;
  height: 21.6145833333rem;
  opacity: 0.75;
  background-image: url("../img/home/index-6/prop.png");
  @apply absolute bottom-0 left-0 bg-no-repeat bg-contain;
}

.index-6.section .search-faq {
  max-width: 23.4375rem;
  margin-top: 15px;
  margin-bottom: 15px;
  @apply mx-auto relative;
}

@media (min-width: 576px) {
  .index-6.section .search-faq {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }
}
.index-6.section .search-faq input {
  width: 100%;
  height: 2.34375rem;
  padding: 0.2604166667rem 2.6041666667rem 0.2604166667rem 0.78125rem;
  @apply font-normal text-base text-main-435B5A placeholder:text-main-435B5A bg-white;
}

.index-6.section .search-faq button {
  width: 2.34375rem;
  top: 0;
  @apply absolute right-0 h-full flex items-center justify-center;
}

.index-6.section .search-faq button i {
  @apply text-main-435B5A text-2xl leading-24px font-light;
}

.index-6.section .search-result {
  max-width: 60.9375rem;
  @apply mx-auto -mt-10px;
}

.index-6.section .search-result .list {
  @apply relative;
}

.index-6.section .search-result .item {
  border-bottom: 0.0520833333rem solid rgba(150, 166, 140, 0.3);
  @apply transition-all;
}

.index-6.section .search-result .item .top {
  @apply flex items-center py-10px transition-all cursor-s-resize;
}

.index-6.section .search-result .item:hover {
  @apply bg-main-799167/30;
}

.index-6.section .search-result .item .id {
  flex: 0 0 2.2916666667rem;
  height: 2.2916666667rem;
  @apply bg-main-799167 rounded-full flex items-center justify-center text-white font-Philosopher text-lg leading-5;
}

.index-6.section .search-result .item .title {
  @apply ml-5 lg:ml-60px font-bold text-base sm:text-lg leading-21px text-main-435B5A text-left;
}

.index-6.section .search-result .item .link {
  @apply ml-auto text-orange font-Philosopher text-lg leading-5 flex items-center justify-center whitespace-nowrap text-left;
}

@media (max-width: 575.98px) {
  .index-6.section .search-result .item .link {
    font-size: 0;
  }
}
.index-6.section .search-result .item .link i {
  @apply text-2xl font-light ml-10px;
}

.index-6.section .search-result .item .bottom {
  padding: 0.5208333333rem 0 1.0416666667rem;
  @apply text-left hidden text-base text-main-799167 leading-6;
}

.index-6.section .search-result .item.active .top {
  @apply cursor-n-resize;
}

.index-7 {
  background: rgba(201, 213, 193, 0.5);
}

@media (min-width: 1280px) {
  .index-7.section {
    padding-top: 8.8541666667rem;
  }
}
.index-7 .box-left {
  padding-top: 1.5625rem;
}

.index-7 .box-left .wrapper {
  @apply mx-auto lg:mx-0;
}

@media (min-width: 1024px) {
  .index-7 .box-left .wrapper {
    height: 31.5625rem;
  }
}
@media (min-width: 1280px) {
  .index-7 .box-left .wrapper {
    max-width: 31.25rem;
  }
}
.index-7 .box-left .wrapper .image-wrapper {
  max-width: calc( 100% -12/1920*100rem );
  @apply mr-auto xl:ml-0;
}

@media (min-width: 1024px) {
  .index-7 .box-left .wrapper .image-wrapper {
    padding-top: 3.90625rem;
  }
}
@media (min-width: 1024px) {
  .index-7 .box-left .wrapper .image-wrapper {
    max-width: 27.5rem;
  }
}
.index-7 .box-left .wrapper .img {
  @apply relative;
}

.index-7 .box-left .wrapper .img::before {
  content: "";
  top: 0.625rem;
  left: 0.625rem;
  border: thin solid #C9D5C1;
  opacity: var(--data-percent, 0);
  @apply absolute w-full h-full;
}

@media (min-width: 1024px) {
  .index-7 .box-left .wrapper .img::before {
    border: 0.0520833333rem solid #C9D5C1;
  }
}
.index-7 .box-left .wrapper .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 50%;
}

.index-7 .box-left .wrapper .img a img, .index-7 .box-left .wrapper .img a iframe, .index-7 .box-left .wrapper .img a video, .index-7 .box-left .wrapper .img a canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

@media (min-width: 1024px) {
  .index-7 .box-left .wrapper .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 66.6666666667%;
  }

  .index-7 .box-left .wrapper .img a img, .index-7 .box-left .wrapper .img a iframe, .index-7 .box-left .wrapper .img a video, .index-7 .box-left .wrapper .img a canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
.index-7 .box-left .wrapper .content {
  z-index: -1;
  @apply lg:absolute w-full h-full lg:bg-main-799167 top-0 right-0 flex items-end;
}

@media (min-width: 1024px) {
  .index-7 .box-left .wrapper .content {
    opacity: var(--data-percent, 0);
    max-width: 20.2604166667rem;
  }
}
.index-7 .box-left .wrapper .content ul {
  padding-top: 1.0416666667rem;
  padding-bottom: 2.0833333333rem;
  @apply italic text-lg leading-21px font-normal lg:text-white text-main-799167;
}

@media (min-width: 1024px) {
  .index-7 .box-left .wrapper .content ul {
    padding-top: 0;
    padding-left: 2.96875rem;
    padding-right: 3.6458333333rem;
    padding-bottom: 3.125rem;
  }
}
.index-7 .box-left .wrapper .content ul li {
  @apply marker:text-main-799167 lg:marker:text-white;
}

.index-7 form {
  @apply grid sm:grid-cols-2 lg:gap-x-30px lg:gap-y-15px gap-10px;
}

.index-7 form .form-group {
  @apply relative h-fit;
}

.index-7 form .form-group input:not([type=submit]) {
  height: 2.34375rem;
  padding: 0.5208333333rem 0.78125rem;
  @apply w-full bg-white font-normal text-base leading-19px text-main-435B5A placeholder:text-main-435B5A;
}

.index-7 form .form-group.form-icon::after {
  font-weight: 300;
  font-size: 1.25rem;
  @apply font-Awesome6 font-light text-main-435B5A absolute top-1/2 -translate-y-1/2 right-15px pointer-events-none;
}

.index-7 form .form-group.form-icon input {
  @apply appearance-none;
}

.index-7 form .form-group.form-icon input::-webkit-inner-spin-button, .index-7 form .form-group.form-icon input::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.index-7 form .form-group.form-date::after {
  content: "";
}

.index-7 form .form-group.form-zip-code::after {
  content: "";
}

.index-7 form .form-ratio, .index-7 form .form-title {
  @apply col-span-full;
}

.index-7 form .form-title {
  @apply font-normal text-lg leading-21px text-black mt-5 mb-10px;
}

.index-7 form .form-ratio .wpcf7-radio, .index-7 form .form-ratio .wpcf7-checkbox {
  @apply grid sm:grid-cols-2 lg:gap-x-11 lg:gap-y-5 gap-10px;
}

@media (min-width: 576px) {
  .index-7 form .form-ratio .wpcf7-radio, .index-7 form .form-ratio .wpcf7-checkbox {
    padding-left: 0.5208333333rem;
  }
}
.index-7 form .form-ratio .wpcf7-radio .wpcf7-list-item, .index-7 form .form-ratio .wpcf7-checkbox .wpcf7-list-item {
  @apply mx-0;
}

.index-7 form .form-ratio .wpcf7-radio .wpcf7-list-item > label > span, .index-7 form .form-ratio .wpcf7-checkbox .wpcf7-list-item > label > span {
  @apply font-normal text-base md:text-sm leading-4 text-main-435B5A cursor-pointer flex items-baseline;
}

.index-7 form .form-ratio .wpcf7-radio .wpcf7-list-item > label > span::before, .index-7 form .form-ratio .wpcf7-checkbox .wpcf7-list-item > label > span::before {
  content: "";
  @apply font-light text-base left-4 font-Awesome6 transition-all mr-15px;
}

.index-7 form .form-ratio .wpcf7-radio .wpcf7-list-item > label input, .index-7 form .form-ratio .wpcf7-checkbox .wpcf7-list-item > label input {
  display: none;
}

.index-7 form .form-ratio .wpcf7-radio .wpcf7-list-item > label input:checked ~ span::before, .index-7 form .form-ratio .wpcf7-checkbox .wpcf7-list-item > label input:checked ~ span::before {
  content: "";
  @apply text-orange;
}

.index-7 form .submit-button {
  @apply mt-15px;
}

.index-7 form .submit-button .btn-input {
  height: 2.6041666667rem;
  @apply rounded-full text-orange flex items-center relative w-full;
}

.index-7 form .submit-button .btn-input::before {
  content: "";
  font-weight: 300;
  font-size: 1.6666666667rem;
  line-height: 1.6666666667rem;
  @apply font-Awesome6 mr-10px;
}

.index-7 form .submit-button .btn-input::after {
  content: var(--data-title);
  @apply font-Philosopher font-bold text-xl leading-22px;
}

.index-7 form .submit-button .btn-input input[type=submit] {
  @apply opacity-0 w-full absolute top-0 left-0 h-full;
}