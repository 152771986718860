.index-7
	background: rgba(201, 213, 193, 0.5)
	&.section
		@media (min-width: 1280px)
			padding-top: r(170px)
	.box-left
		padding-top: r(30px)
		.wrapper
			@apply mx-auto lg:mx-0
			@media (min-width: 1024px)
				height: r(606px)
			@media (min-width: 1280px)
				max-width: r(600px)
			.image-wrapper
				max-width: calc( 100% -12/1920*100rem )
				@apply mr-auto xl:ml-0
				@media (min-width: 1024px)
					padding-top: r(75px)
				@media (min-width: 1024px)
					max-width: r(528px)
			// &.done-animation
			.img
				@apply relative
				&::before
					content: ""
					top: r(12px)
					left: r(12px)
					border: thin solid #C9D5C1
					opacity: var(--data-percent,0)
					@apply absolute w-full h-full
					@media (min-width: 1024px)
						border: r(1px) solid #C9D5C1
				a
					+img-ratio(1/2)
					@media (min-width: 1024px)
						+img-ratio(352/528)
			.content
				z-index: -1
				@apply lg:absolute w-full h-full lg:bg-main-799167 top-0 right-0 flex items-end
				@media (min-width: 1024px)
					opacity: var(--data-percent,0)
					max-width: r(389px)
				ul
					padding-top: r(20px)
					padding-bottom: r(40px)
					@apply italic text-lg leading-21px font-normal lg:text-white text-main-799167
					@media (min-width: 1024px)
						padding-top: 0
						padding-left: r(57px)
						padding-right: r(70px)
						padding-bottom: r(60px)
					li
						@apply marker:text-main-799167 lg:marker:text-white
	form
		@apply grid sm:grid-cols-2 lg:gap-x-30px lg:gap-y-15px gap-10px
		.form-group
			@apply relative h-fit
			input
				&:not([type="submit"])
					height: r(45px)
					padding: r(10px) r(15px)
					@apply w-full bg-white font-normal text-base leading-19px text-main-435B5A placeholder:text-main-435B5A
			&.form-icon
				&::after
					font-weight: 300
					font-size: r(24px)
					@apply font-Awesome6 font-light text-main-435B5A absolute top-1/2 -translate-y-1/2 right-15px pointer-events-none
				input
					@apply appearance-none
					&::-webkit-inner-spin-button,&::-webkit-calendar-picker-indicator
						opacity: 0
			&.form-date
				&::after
					content: "\e472"
			&.form-zip-code
				&::after
					content: "\f601"
		.form-ratio,.form-title
			@apply col-span-full
		.form-title
			@apply font-normal text-lg leading-21px text-black mt-5 mb-10px
		.form-ratio
			.wpcf7-radio,.wpcf7-checkbox
				@apply grid sm:grid-cols-2 lg:gap-x-11 lg:gap-y-5 gap-10px
				@media (min-width: 576px)
					padding-left: r(10px)
				.wpcf7-list-item
					@apply mx-0
					> label
						> span
							@apply font-normal text-base md:text-sm leading-4 text-main-435B5A cursor-pointer flex items-baseline
							&::before
								content: "\f0c8"
								@apply font-light text-base left-4 font-Awesome6 transition-all mr-15px
						input
							display: none
							&:checked
								~ span
									&::before
										content: "\f14a"
										@apply text-orange
		.submit-button
			@apply mt-15px
			.btn-input
				height: r(50px)
				@apply rounded-full text-orange flex items-center relative w-full
				&::before
					content: "\e20a"
					font-weight: 300
					font-size: r(32px)
					line-height: r(32px)
					@apply font-Awesome6 mr-10px
				&::after
					content: var(--data-title)
					@apply font-Philosopher font-bold text-xl leading-22px
				input[type="submit"]
					@apply opacity-0 w-full absolute top-0 left-0 h-full
