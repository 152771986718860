.index-5
	&.section
		padding-bottom: r(200px)
		@media (min-width: 1280px)
			padding-top: r(130px)
			padding-bottom: r(437px)
	.index-5-slide
		max-width: r(1170px)
		margin: 0 auto
		margin-top: r(30px)
		@media (min-width: 1280px)
			margin-top: r(55px)
		.icon
			width: r(208px)
			@apply mx-auto
			a
				+img-ratio(1/1,contain)
				@apply bg-white rounded-full
				img
					padding: 5%
					max-height: r(120px)
					left: 50%
					top: 50%
					transform: translate(-50%,-50%)
