.search-page
	background-color: #f0f0f2
	.search-form
		form
			@apply relative
			input
				padding: r(10px) r(20px)
				border: thin solid
				padding-right: r(45px)
				@apply w-full bg-transparent transition-all border-main-435B5A rounded-lg
				&:not(:placeholder-shown)
					@apply bg-main-799167/[.15] border-transparent
			button
				width: r(40px)
				@apply h-full absolute top-0 right-0 flex items-center justify-center
	.search-query
		@apply my-15px
	.found-nothing
		background-image: url(../img/nothing.png)
		height: 30vh
		@apply bg-no-repeat bg-center
	.found-nothing-title
		@apply text-xl uppercase relative font-bold  text-main-435B5A
