.index-2
	background: #EAFAFE
	.image-cover
		@apply absolute bottom-0 left-0 w-full h-full
		img
			@apply w-full h-full object-contain object-bottom
	.absolute-logo
		width: 25vw
		height: 25vw
		top: 25%
		right: r(10px)
		@apply absolute opacity-10
		@media (min-width: 1280px)
			right: r(20px)
			top: 21%
			width: r(450.55px)
			height: r(449.48px)
		img
			@apply w-full h-full object-contain
	.button
		@apply mb-15px mt-5
	.container
		&.section
			@media (min-width: 1280px)
				padding-top: r(100px)
				padding-bottom: r(470px)
	.description
		max-width: r(1008px)
		@apply font-normal text-lg leading-21px text-333333 text-justify md:text-center
	.time-line-tree
		padding-left: 15px
		padding-top: 10px
		padding-bottom: 10px
		@apply font-Philosopher relative
		@media (min-width: 768px)
			padding-top: r(55px)
			padding-bottom: r(100px)
			padding-left: 0
		@media (min-width: 1280px)
			padding-bottom: r(235px)
		&::before
			content: ""
			width: r(1px)
			top: 0
			left: 15px
			height: 100%
			border: thin dashed #C9D5C1
			@apply absolute
			@media (min-width: 768px)
				left: 50%
				transform: translateX(-50%)
			@media (min-width: 1280px)
				height: var(--data-percent)
				border: r(1px) dashed #C9D5C1

		.grid
			padding-left: 35px
			@apply will-change-transform
			@media (min-width: 768px)
				padding-left: 0
			& + .grid
				margin-top: r(70px)
		.id
			top: 50%
			left: 0
			transform: translate(-50%,-50%)
			@apply text-white font-bold text-base leading-18px absolute
			@media (min-width: 768px)
				left: 50%
			span
				z-index: 1
				width: 30px
				height: 30px
				@apply relative flex items-center justify-center text-white
				@media (min-width: 768px)
					width: r(40px)
					height: r(40px)
				&::before
					content: ""
					left: 50%
					top: 50%
					transform: translate(-50%,-50%)
					z-index: -1
					@apply bg-orange rounded-full absolute w-full h-full w-full h-full
				&::after
					content: ""
					left: 50%
					top: 50%
					transform: translate(-50%,-50%)
					width: calc( 100% + 20/1920*100rem)
					height: calc( 100% + 20/1920*100rem)
					background: rgba(#F37926 ,.35 )
					z-index: -1
					animation: heartbeat 1.5s ease-in-out infinite both
					@apply rounded-full absolute

		.time
			@apply font-bold text-lg leading-5 text-main-435B5A
		.content
			border-radius: r(10px)
			padding: r(10px) r(20px)
			@apply font-bold text-xl leading-22px text-left text-white bg-orange flex items-center
			@media (min-width: 768px)
				margin-left: r(40px)
			@media (min-width: 1280px)
				margin-left: r(60px)
		.time-line
			margin-left: 5px
			@apply flex items-center md:justify-end relative
			@media (min-width: 768px)
				margin-left: unset
				margin-right: r(40px)
				padding-right: r(65px)
			&::before
				content: ""
				position: absolute
				width: r(42px)
				height: r(1px)
				left: 120%
				@apply absolute bg-orange
				@media (min-width: 768px)
					left: unset
					right: 0
			.time
				padding: r(5px) r(20px) r(5px) r(20px)
				@apply relative text-left
				@media (min-width: 768px)
					padding: r(5px) r(20px) r(5px) r(45px)
				@media (min-width: 1280px)
				&::before,&::after
					content: ""
					left: 0
					height: 50%
					width: 100%
					background: white
					z-index: -2
					@apply absolute
				&::before
					top: 0
					transform: skew(50deg)
				&::after
					bottom: 0
					transform: skew(-50deg)
				&:nth-of-type(odd)
					&::before,&::after
						@apply bg-white
				&:nth-of-type(even)
					&::before,&::after
						@apply bg-main-C9D5C1
