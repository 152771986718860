footer
	border-top: thin solid #ccc
	border-bottom: r(10px) solid #799167
	&.section
		padding-left: r(30px)
		padding-right: r(30px)
		padding-bottom: 10px
		@media (min-width: 1280px)
			padding-bottom: r(20px)
			padding-left: r(110px)
			padding-right: r(110px)
			padding-top: r(80px)
	.logo
		img
			height: r(170px)
			@apply object-contain
	.footer-wrapper
		margin-bottom: r(30px)
		@media (min-width: 1024px)
			margin-bottom: r(45px)
	.title
		margin-bottom: r(27px)
		@apply font-Philosopher font-bold text-2xl leading-27px text-main-435B5A
	.ctn
		@apply font-normal text-lg leading-22px text-333333
	.box-info
		@media (max-width: 1023.98px)
			@apply text-center
		&:nth-of-type(1)
			@media (max-width: 1023.98px)
				order: 2
		&:nth-of-type(2)
			@apply text-center
			@media (max-width: 1023.98px)
				order: 1
				@apply col-span-full
		&:nth-of-type(3)
			@media (max-width: 1023.98px)
				order: 3
			.wrapper
				@media (min-width: 1024px)
					max-width: r(350px)
					@apply ml-auto

	.social-icons
		@apply flex items-center mb-5 justify-center lg:justify-start
		a
			border: r(1px) solid #435B5A
			width: r(45px)
			height: r(45px)
			@apply rounded-full flex items-center justify-center transition-all
			&:hover
				@apply bg-main-435B5A shadow-lg shadow-main-435B5A
				i
					@apply text-white
			& + a
				@apply ml-5 sm:ml-30px
		i
			@apply text-2xl text-main-435B5A font-normal transition-all
	.bct
		@apply mt-5
		img
			width: r(164px)
			height: r(60px)
			@apply object-contain
	form
		input
			height: r(50px)
			@apply w-full
		.form-group
			input
				background: #F9F9F9
				padding: r(10px) r(30px)
				@apply rounded-full font-normal text-base text-main-435B5A mb-10px
		.btn-input
			height: r(50px)
			width: 100% !important
			@apply bg-orange rounded-full text-white flex items-center justify-center relative
			&::before
				content: "\e20a"
				font-weight: 300
				font-size: r(32px)
				line-height: r(32px)
				@apply font-Awesome6 mr-10px
			&::after
				content: var(--data-title)
				@apply font-Philosopher font-bold text-xl leading-22px
			input[type="submit"]
				@apply opacity-0 w-full absolute top-0 left-0 h-full
			.wpcf7-spinner
				left: unset !important
				right: 100%!important
	.bottom
		border-top: 1px solid #D6DEE0
		@apply pt-15px mt-5
		ul
			@apply flex items-center justify-center flex-wrap
			li
				@apply relative text-333333 font-normal text-base leading-19px transition-all
				@media (max-width: 767.98px)
					@apply text-center
					&:first-child
						flex: 0 0 100%
				&:hover
					@apply text-orange
				& + li
					@apply ml-5px md:ml-10px pl-5px md:pl-10px
					@media (min-width: 768px)
						&::before
							content: ""
							height: r(20px)
							width: 1px
							@apply absolute top-1/2 -translate-y-1/2 left-0 bg-333333
							@media (min-width: 1280px)
								width: r(1px)

.fa-square-facebook
	&::before
		content: "\f082"
