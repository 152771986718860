.button
	transition: .3s all ease-in-out
	user-select: none
	*
		@media (min-width: 576px)
			white-space: nowrap

	.btn
		user-select: none
		cursor: pointer
		width: fit-content
		transition: .3s all ease-in-out
		position: relative
		display: flex
		justify-content: center
		align-items: center
		padding: r(7px) r(19.5px)
		border-radius: r(90px)
		border: r(1px) solid transparent
		height: 35px
		@apply text-white text-base transition-all
		@media (min-width: 1280px)
			height: r(50px)
		&.btn-header
			height: r(30px)
			width: r(153px)
			@apply text-main-435B5A border-main-435B5A text-sm
			i
				@apply mr-10px text-base
			&:hover
				@apply bg-main-799167 border-transparent text-white
		&.btn-1
			@apply bg-main-435B5A text-white
			&:hover
				@apply bg-main-799167
		&.btn-2
			@apply bg-orange text-xl text-white
			i
				font-weight: 300
				font-size: r(24px)
				@apply mr-10px
			&:hover
				@apply bg-main-799167
		&.btn-3
			@apply text-orange text-xl leading-22px font-bold font-Philosopher
			i
				font-size: r(32px)
				line-height: r(32px)
				@apply font-light mr-10px
		&.has-input-submit
			@apply relative
			span.wpcf7-spinner
				left: calc( 100% + 30/1920*100rem )
				@apply absolute left-full top-1/2 -translate-y-1/2
			&::before
				content: attr(data-title)
				@apply normal-case
			input
				font-size: 0
				@apply absolute top-0 left-0 w-full h-full

.button-wrapper
	@apply flex gap-10px
